import React, { Component} from 'react';
import '../styles/JumboTron.css';
import { withRouter } from './withRouter';
import { Dropdown, Badge, Stack, Table, Col, Row, Button, Container, Card  } from "react-bootstrap";
import GeneralUtils from './investment_details/GeneralUtils';
import { getWithExpiry } from '../localstorageutils/localstore.js';
import { motion, AnimatePresence } from 'framer-motion';
import {divvariants} from './utils/MotionVariants.js';
import TradeUtils from './utils/TradeUtils.js';

class UpcomingInvestmentsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      "investments":[],
      "myinvestments":[],
      /*"verticals":[1,2,3,4,5,6,7,8,9,10,11,12],*/
      "verticals":[],
      "investment":{"name":"", "unit_value":0},
      "show_interest_modal": false,
      "status":0,
      "displaymsg": "Loading Investments",
      "contains_page":["viewflowerturbines","viewxenabrewery","viewpsyonic"],
      "show_interest_card":false,
      "interest_message":"Show"
    }
    this.authinfo=getWithExpiry("userinfo");
  };

  componentDidMount() {
        //this.getupcominginvestments();
        //this.getverticals();
        if (this.authinfo)
        {
            this.getverticals();
            this.getupcominginvestments("Retail");
            this.getuserinvestments();
        }
  };

  onDataChange = (key, value) => {
    if(key === "interestedunits")
    {
        this.setState({"interestedunits":Number(value)});
    }
  };

  saveuserinvestment = () => {
    GeneralUtils.saveuserinvestment(this);
  };

  show_interest = (ev, inv) => {
    console.log("show_interest");
    this.setState({"investment":inv});
    this.setState({"show_interest_modal":true});
  };

  hide_interest = () => {
    console.log("hide_interest");
    this.setState({"show_interest_modal":false});
  };

  getverticals = () => {
        if(this.authinfo === null)
        {
            // route to login.
            return;
        }
        console.log("Trying GetVerticals Data");
        const USER_API = '/rest/getverticals';
        this.setState({"status":1});
        fetch(USER_API, {
                  method: "GET",
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                if(data.verticals.length === 0)
                    this.setState({"displaymsg":"No Open Investments Available, Check back later"});
                this.setState({"status":data.statuscode});
                let obj=data.verticals;
                const keysSorted = Object.keys(obj).sort((a, b) => obj[b] - obj[a]);
                const result = {};
                keysSorted.forEach(key => { result[key] = obj[key]; });
                this.setState({"verticals":result});
         });
  };

  showinvestments = (ev, vertical) => {
        this.getupcominginvestments(vertical)
  }

  getupcominginvestments = (vertical) => {
        if(this.authinfo === null)
        {
            // route to login.
            return;
        }
        console.log("Trying GetInvestments Data - " + vertical);
        const USER_API = '/rest/getupcominginvestments?vertical=' + vertical;
        this.setState({"status":1});
        fetch(USER_API, {
                  method: "GET",
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                if(data.investments.length === 0)
                    this.setState({"displaymsg":"No Open Investments Available, Check back later"});
                this.setState({"status":0});
                this.setState({"investments":data.investments});
         });
  };

  getuserinvestments = () => {
        if(this.authinfo === null)
        {
            // route to login.
            return;
        }
        console.log("Trying GetUserInvestments Data");
        const USER_API = '/rest/getuserinvestments';
        this.setState({"status":1});
        var indata = JSON.stringify({"user": this.authinfo.email});
        fetch(USER_API, {
                  method: "POST",
                  body: indata,
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                this.setState({"status":0});
                this.setState({"userinvestments":data.user_investments});
         });
   };

   show_asset = (obj, variant) => {
    console.log(variant);
    let inv_name='view' + variant.name.replace(" ","").toLowerCase();
    if(this.state.contains_page.includes(inv_name))
    {
         this.props.navigate('/view' + variant.name.replace(" ","").toLowerCase(), {state: variant});
    }
    else
    {
         this.props.navigate('/viewinvestment', {state: variant});
    }
  };

  arrayChunk = (arr, n) => {
    const array = arr.slice();
    const chunks = [];
    while (array.length) chunks.push(array.splice(0, n));
    return chunks;
  };

  toggleInterestCard = async() => {
        if(this.state.show_interest_card === true)
        {
            this.setState({"show_interest_card":false});
            this.setState({"interest_message":"Show"});
        }
        else
        {
            this.setState({"show_interest_card":true});
            this.setState({"interest_message":"Hide"});
        }
  }

  render() {
   return (
     <Container className="px-0" fluid={true}>
{/*
        <div class="row" style={{"padding-bottom":"80px"}}>
           <div class="col-sm-12" align="center">
             <Card bg="light" data-bs-theme="light">
                 <Card.Header>
                    <h5> Our Team is always looking for Various Opportunites to bring to our investors. </h5>
                 </Card.Header>
             </Card>
           </div>
        </div>
*/}

        <Row>
          <Col lg="2">
           <Button onClick={this.toggleInterestCard} style={{'margin-left':'50px'}} variant="outline-light"> {this.state.interest_message} Interested Shares </Button>
           </Col>
           <Col lg="8">
            <Dropdown variant="outline-light">
                <Dropdown.Toggle id="dropdown-autoclose-inside" variant="outline-light">
                  Choose Startup Industry
                </Dropdown.Toggle>
                 <Dropdown.Menu style={{"max-height": "80vh", "overflow-y": "auto", "background-image":"repeating-linear-gradient(40deg, rgb(242 239 239), rgb(44, 42, 46) 0%, rgb(68 89 181 / 60%) 1%)", width:"80%"}}>
                         {Object.keys(this.state.verticals).map((item, i) => (
                             <Dropdown.Item variant="outline-light" onClick={(ev) => this.showinvestments(ev, item)}> {item}  <Badge bg="dark">{this.state.verticals[item]}</Badge> </Dropdown.Item>
                    ))}
                 </Dropdown.Menu>
             </Dropdown>
           </Col>
        </Row>
        {this.state.show_interest_card && this.state.userinvestments !== undefined && (
          <AnimatePresence>
           <motion.div variants={divvariants} initial="initial" animate="animate" exit="exit">
             <Card bg="dark" id="show_interest_card" style={{margin: '40px', "width":"-webkit-fill-available", "background-image":"repeating-linear-gradient( 0deg, rgb(242 239 239), rgb(44, 42, 46) 0%, rgb(68 89 181 / 60%) 1%)", "z-index":4048}}>
          <Card.Header>
                {this.authinfo.name}'s Interested Upcoming Shares 
          </Card.Header>
         <Card.Body>
            <Table striped bordered hover size="sm">
             <thead>
                <tr>
                    <td> Investment Name </td>
                    <td> Interested Units </td>
                    <td> Unit Value </td>
                </tr>
             </thead>
             {(this.state.userinvestments !== undefined && this.state.userinvestments.length !== 0) && this.state.userinvestments.filter(inv => inv.state === "interest").map(( listvalue, index ) => {
                return (
                    <tr key={index}>
                     <td>{listvalue.invname}</td>
                     <td>{listvalue.units}</td>
                     <td>{TradeUtils.formatCurrency(listvalue.unit_value)}</td>
                    </tr>
                );
            })}
            </Table>
          </Card.Body>
        </Card>
        </motion.div>
       </AnimatePresence>
        )}

       {GeneralUtils.getShowInterestModal(this)}
        <Row style={{'margin':'2px', 'margin-bottom':'50px', 'z-index':-1}}>
{/*
           <Col md={3} style={{ "max-height": "70vh", "overflow-y": "auto", "background-image":"repeating-linear-gradient(40deg, rgb(242 239 239), rgb(44, 42, 46) 0%, rgb(68 89 181 / 60%) 1%)" }}>
                <Stack gap={1}>
                    {Object.keys(this.state.verticals).map((item, i) => (
                        <div className="p-1"> <Button className="w-100" variant="outline-light" onClick={(ev) => this.showinvestments(ev, item)}> {item}  <Badge bg="dark">{this.state.verticals[item]}</Badge> </Button></div>
                    ))}
                </Stack>
            </Col>
*/}
            <Col md={12} style={{ "max-height": "70vh", "overflow-y": "auto", "background-image":"repeating-linear-gradient(-40deg, rgb(242 239 239), rgb(44, 42, 46) 0%, rgb(68 89 181 / 60%) 1%)" }}>
       {this.arrayChunk(this.state.investments, 4).map((row, i) => (
        <Row style={{'margin':'2px', 'margin-bottom':'50px'}}>
         {row.map((variant) => (
           <Col md={3}>
          <motion.div whileHover={{ scale: 1.1 }}>
            <Card
              key={variant.id}
              text={variant.name}
              onClick={(ev) => this.show_interest(ev, variant)}
            >
              <Card.Img variant="top" src={variant.doc_loc + "/main.jpeg"}  style={{ opacity:0.8 }}/>
              <Card.Body style={{}}>
                <Card.Title>{variant.name}</Card.Title>
              </Card.Body>
            </Card>
           </motion.div>
           </Col>
          ))}
          {this.state.investments.length === 0 && ( <Container> 
                <Card>
                    <Card.Body>
                        <Card.Text>
                            <h1> {this.state.displaymsg} </h1>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Container> )
          }
        </Row>
       ))}
        </Col>
      </Row>
    </Container>
   );
  };

};

export default withRouter(UpcomingInvestmentsComponent);
