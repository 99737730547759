const initialState = {
  authname: {"name":null, "email":null},
  signUpModal: {
    open: false
  }
}

const reducer = (state = initialState, action) => {

  if (action.type === 'SAVE_LOGIN_NAME') {
    localStorage.setItem("authdetails",JSON.stringify(action.payload));
    sessionStorage.setItem("authdetails",JSON.stringify(action.payload));
    state.authname = action.payload;
  }

  if (action.type === 'LOAD_LOGIN_INFO') {
    let payload=JSON.parse(localStorage.getItem("authdetails"));
    if (payload !== undefined && payload !== null)
    {
        state.authname=payload;
        console.log(state.authname);
    }
  }
  
  if (action.type === 'DO_LOGOUT') {
     localStorage.clear();
     state.authname={"name":null, "email":null};
  }

  return state;
}

export default reducer;
