import React, { Component} from 'react';
import '../styles/JumboTron.css';
import { withRouter } from './withRouter';
import { Col, Row, Card } from "react-bootstrap";
import Figure from 'react-bootstrap/Figure';
import HeadAndBodyCard from './HeadAndBodyCard.js';

class AssessStartupComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      "email":"",
      "password":"",
      "data":"failure",
    }
  };

  render() {
    return (
       <Card bg="light" data-bs-theme="light">
         <Card.Header>
           <Row style={{'margin':'4px', 'text-align': 'center'}}>
               <h2>  Assessing whether a venture is suitable for investment </h2>
           </Row>
         </Card.Header>
         <Card.Body>
            <Row>
               <Col md={2}> </Col>
               <Col md={8}>
                 <div>
                     <Figure.Image  height={320} src="images/strategy.jpg"/>
                 </div>
               </Col>
               <Col md={2}> </Col>
            </Row>

            <br/>
            <HeadAndBodyCard title="Understand the industry" msgs={["To evaluate a startup’s potential for success, it’s essential to study the sector it operates in. It involves knowing the market’s current size, anticipated growth, and competitive landscape.", "Moreover, regulatory challenges and other variables unique to the sector can impact the startup’s success, so assessing these is also essential. Some valuable resources for market research involve industry reports, market research companies, and business associations."]} txtcolor="floralwhite" />
            <br/>
    
           <HeadAndBodyCard  txtcolor="floralwhite" title="Evaluate the market" msgs={[ "To succeed, it is crucial to understand the target market. This includes investigating the size of the market, the target audience, and the client pain points the startup tries to solve.", " By analysing these variables, investors can understand the startup’s market penetration and sales growth prospects."]}/>
            <br/>
            <HeadAndBodyCard txtcolor="floralwhite" title="Assess the team" msgs={["A startup’s success is primarily determined by the expertise, abilities, and track record of the founding members and their team. It’s essential to examine the team’s expertise in the industry, their skills in building successful startups, and their capacity for executing the business plan.", "Investors should also consider the team’s communication abilities and capacity to work together efficiently."]}/>
            <br/>
            <HeadAndBodyCard txtcolor="floralwhite" title="Evaluate the product" msgs={["Understanding the startup’s service or product is crucial to evaluating its success potential. It includes evaluating the product or service’s uniqueness, potential market demand, and potential intellectual property concerns. Moreover, assessing the product’s scalability and growth potential is essential."]}/>
            <br/>
            <HeadAndBodyCard txtcolor="floralwhite" title="Check the traction" msgs={["It includes evaluating the startup’s client acquisition and sales growth to evaluate its market demand and product-market fit. Investors should look at the company’s current user growth, engagement rate, customer retention levels, and profit growth to get a sense of the firm’s potential for long-term success.", " Moreover, examining the startup’s consumer feedback and possible negative reviews is essential to identify potential item or market issues."]}/>
            <br/>
            <HeadAndBodyCard txtcolor="floralwhite" title="Assess the competitive landscape" msgs={[ "It includes analysing the startup’s competitors and their position in the market to identify opportunities for differentiation and long-term success."," Investors should assess the strength of the company’s current value proposition compared to its rivals and any possible barriers to market entry. Moreover, examining the company’s potential for establishing a long-term competitive edge is essential."]}/>
            <br/>
            <HeadAndBodyCard txtcolor="floralwhite" title="Evaluate the business model" msgs={["It includes analysing the startup’s revenue streams, cost structure, and growth potential. Investors should research the company’s pricing strategy, customer acquisition costs, and prospects for recurring revenue.", " A venture with recurring revenue streams, like a subscription-based business strategy, may have a more viable revenue stream than a venture that relies entirely on one-time sales. The business should have a solid and expandable business strategy that attracts investment opportunities."]}/>
            <br/>
            <HeadAndBodyCard txtcolor="floralwhite" title="Look at the financials" msgs={["Evaluating the company’s financial statements is crucial to analysing its potential for success. It includes analysing the startup’s revenue, expenditure, and cash flow to evaluate its financial health and growth potential.", "Investors should assess the company’s burn rate, cash runway, and possibilities for raising additional funding. Additionally, examining the company’s potential for producing positive cash flow and profitability is essential."]} />
            <br/>
            <HeadAndBodyCard txtcolor="floralwhite" title="Conduct due diligence" msgs={[" Conducting due diligence is essential for recognising any risks associated with investing in a new company. It includes thoroughly investigating the company’s legal, financial, and functional history to detect possible risks."," Investors should analyse the company’s regulatory compliance, intellectual property rights, and possibilities for litigation."]}/>
            <br/>
            <HeadAndBodyCard txtcolor="floralwhite" title="Negotiate terms" msgs={[" Once a shareholder has selected to invest in a venture, it’s essential to negotiate the terms of the investment. It includes agreeing on the investment amount, the valuation of the startup, and any other conditions associated with the investment.", "Shareholders should work with legal advisers to ensure that the terms of the investment are legitimate and enforceable by law."]}/>
            <br/>
            <HeadAndBodyCard txtcolor="floralwhite" title="Final Thoughts" msgs={["To evaluate whether a venture is a good investment option, shareholders must consider numerous vital factors. These include evaluating the company’s market potential, assessing the team’s functionality and experience, and investigating the service or goods offered.", "By undertaking extensive analysis and research, investors can better comprehend the risks and rewards of a specific investment opportunity and make informed decisions that comply with their investment objectives and risk tolerance.", "Moreover, working with a professional startup investor or venture capital firm can provide helpful advice and assistance throughout the investment strategy"]}/>.
            <br/>
         </Card.Body>
        </Card>
  );
};

};

export default withRouter(AssessStartupComponent);
