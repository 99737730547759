import React, { Component} from 'react';
import '../styles/JumboTron.css';
import { withRouter } from './withRouter';
import { Col, Row, Button, Container, Card  } from "react-bootstrap";
import { getWithExpiry } from '../localstorageutils/localstore.js';

class InvestmentsComponent extends Component {

  constructor(props) {
    super(props);
    this.authinfo=getWithExpiry("userinfo");
    this.state = {
      "investments":[],
      "myinvestments":[],
      "status":0,
      "loading":true,
      "displaymsg": "Loading Investments",
      "contains_page":["viewflowerturbines","viewxenabrewery","viewpsyonic"],
    }
  };

  componentDidMount() {
        this.getinvestments();
        if (this.authinfo)
        {
            this.getuserinvestments();
        }
  };

  getinvestments = () => {
        if(this.authinfo === null)
        {
            // route to login.
            return;
        }
        console.log("Trying GetInvestments Data");
        const USER_API = '/rest/getinvestments';
        this.setState({"status":1});
        fetch(USER_API, {
                  method: "GET",
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                if(data.investments.length === 0)
                    this.setState({"displaymsg":"No Open Investments Available, Check back later, Our Team is working diligently at various awesome Opportunites to bring to our investors. We would want to take sufficient time to evaluate them with our hardworking accounting & legal team. Meanwhile please browse upcoming investments and show your interest on which companies you would like to invest."});
                this.setState({"status":0});
                this.setState({"investments":data.investments});
                this.setState({"loading":false});
         });
  };

  getuserinvestments = () => {
        if(this.authinfo === null)
        {
            // route to login.
            return;
        }
        console.log("Trying GetUserInvestments Data");
        const USER_API = '/rest/getuserinvestments';
        this.setState({"status":1});
        var indata = JSON.stringify({"user": this.authinfo.email});
        fetch(USER_API, {
                  method: "POST",
                  body: indata,
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                this.setState({"status":0});
                this.setState({"userinvestments":data.user_investments});
         });
   };

   show_asset = (obj, variant) => {
    console.log(variant);
    let inv_name='view' + variant.name.replace(" ","").toLowerCase();
    if(variant.custattbs.custtype !== undefined && variant.custattbs.custtype !== null)
    {
         this.props.navigate('/viewREinvestment', {state: variant});
    }
    else if(this.state.contains_page.includes(inv_name))
    {
         this.props.navigate('/view' + variant.name.replace(" ","").toLowerCase(), {state: variant});
    }
    else
    {
         this.props.navigate('/viewinvestment', {state: variant});
    }
  };

  arrayChunk = (arr, n) => {
    const array = arr.slice();
    const chunks = [];
    while (array.length) chunks.push(array.splice(0, n));
    return chunks;
  };

  render() {
   return (
     <Container className="vh-100 px-0" fluid={true}>
       {this.state.loading === false && this.state.investments.length === 0 && (
        <div class="row" style={{"padding-bottom":"80px"}}>
           <div class="col-sm-12 card-col" align="center">
             <Card className="assets-card">
                 <Card.Body>
                    <h5> {this.status.displaymsg} </h5>
                   <hr/>
                   <ul>
                     <li href="/upcominginvestments"> UpComing Investments </li>
                   </ul>
                 </Card.Body>
             </Card>
           </div>
        </div>
       )}
       {this.arrayChunk(this.state.investments, 3).map((row, i) => (
        <Row style={{'margin':'2px', 'margin-bottom':'50px'}}>
         {row.map((variant) => (
           <Col md={3} className="card-col">
            <Card key={variant.id} text={variant.name} className="assets-card">
              <Card.Header>
                 <Card.Title>{variant.name}</Card.Title>
              </Card.Header>
              <Card.Img variant="top" src={variant.doc_loc + "/main.jpeg"}  style={{ opacity:0.8 }}/>
              <Card.Body>
                <Card.Text>
                    {variant.desc}
                </Card.Text>
                <Button variant="primary" onClick={(ev) => this.show_asset(ev, variant)}>Show {variant.name}</Button>
              </Card.Body>
            </Card>
           </Col>
          ))}
          {this.state.investments.length === 0 && ( <Container> 
                <Card>
                    <Card.Body>
                        <Card.Text>
                            <h1> {this.state.displaymsg} </h1>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Container> )
          }
        </Row>
       ))}
    </Container>
   );
  };

};

export default withRouter(InvestmentsComponent);
