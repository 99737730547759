import React, { Component} from 'react';
import '../styles/JumboTron.css';
import { withRouter } from './withRouter';
import { Col, Button, Row, Container, Card } from "react-bootstrap";

class WelcomePageComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      "email":"",
      "password":"",
      "data":"failure",
    }
  };

  show_my_assets = () => {
    this.props.navigate('/myinvestments', {});
  }

  render() {
    return (
     <Container className="px-0" fluid={true}>
        <Row style={{margin:'50px'}}>
          <Col className="card-col">
            <Card className="assets-card">
              <Card.Header> <h5> Your Current Investments </h5></Card.Header>
              <Card.Img variant="top" src={"crowdseeds/assets/myassets.jpeg"}  style={{  }}/>
              <Card.Body>
                <Button variant="primary" onClick={this.show_my_assets}>Show my assets</Button>
              </Card.Body>
            </Card>
           </Col>

          <Col className="card-col">
            <Card className="assets-card">
              <Card.Header><h5> Explore New RealEstate Investments </h5></Card.Header>
              <Card.Img variant="top" src={"crowdseeds/assets/myreassets.jpeg"}  style={{  }}/>
              <Card.Body>
                <Button variant="primary">Explore Now</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row style={{margin:'50px'}}>
           <Col className="card-col">
              <Card className="assets-card">
              <Card.Header><h5> Trade </h5></Card.Header>
              <Card.Img variant="top" src={"crowdseeds/assets/trading.jpeg"}  style={{  }}/>
              <Card.Body>
                <Button variant="primary" href="/alltrades">Take Me To Trading</Button>
              </Card.Body>
            </Card>
         </Col>
        <Col className="card-col">
          <Card className="assets-card">
            <Card.Header><h5> Explore New Startup Opportunities </h5></Card.Header>
            <Card.Img variant="top" src={"crowdseeds/assets/newopps.jpeg"}  style={{  }}/>
            <Card.Body>
              <Button variant="primary" href="/upcominginvestments">Explore Now</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
   </Container>
  );
};

};

export default withRouter(WelcomePageComponent);
