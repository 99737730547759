import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {LinkContainer} from 'react-router-bootstrap'
import { getWithExpiry } from '../localstorageutils/localstore.js';

class CollapsibleHeader extends Component {
  constructor(props) {
    super(props);
    this.authinfo=getWithExpiry("userinfo");
    console.log(this.authinfo);
  };

 render() {
   return (
    <Navbar collapseOnSelect expand="lg" data-bs-theme="dark" style={{ "background-color":"#fffaf0", "background-image": "repeating-linear-gradient(90deg, rgb(87, 87, 162), rgb(100, 84, 55), blue 1px)", "box-shadow": "rgb(92 63 192) 0px 0px 0px 8px inset" }} className='sticky-top'>
      <Container>
        <LinkContainer to="/">
            <Navbar.Brand>
            <h1 class="herodesign"> StartupXchange </h1>
            </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end" style={{"color":"white"}}>
          <Nav activeKey={window.location.pathname} className="mx-auto" variant="underline">
            <Nav.Link href="/info" >Learn</Nav.Link>
            <NavDropdown title="Startup Investments" id="collasible-nav-dropdown" style={{ color:"white", "background-image":"repeating-linear-gradient(90deg, rgb(87, 87, 162), rgb(100, 84, 55), blue 1px)" }}>
              <LinkContainer to="/investments">
                <NavDropdown.Item>Open Investments </NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Divider />
              <LinkContainer to="/closedinv">
                <NavDropdown.Item>Closed Investments </NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Divider />
              <LinkContainer to="/upcominginvestments">
                <NavDropdown.Item>Upcoming Investments </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <Nav.Link href="/alltrades">Trading</Nav.Link>
{/*

            <NavDropdown title="RealEstate Investments" id="collasible-nav-dropdown">
              <LinkContainer to="/openinv">
                <NavDropdown.Item> Land Investments </NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Divider />
              <LinkContainer to="/cloud">
                <NavDropdown.Item href="/cloud">  Office Investments </NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Divider />
              <LinkContainer to="/ininfra">
                <NavDropdown.Item href="/ininfra"> Residential Tower Investments </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
*/}
            {(this.authinfo === null || this.authinfo.name === null) && 
             (<NavDropdown title="Account" id="collasible-nav-dropdown">
              <LinkContainer to="/login">
                <NavDropdown.Item> Sign In </NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Divider />
              <LinkContainer to="/signup">
                 <NavDropdown.Item > Sign Up </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown> )}
            <Nav.Link href="#deets">About us</Nav.Link>
            {(this.authinfo !== null && this.authinfo.name !== null) && 
                (<NavDropdown title={this.authinfo.name}>
                  <LinkContainer to="/welcome">
                    <NavDropdown.Item> My Account  </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/logout">
                    <NavDropdown.Item> LogOut  </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>)
              }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
 };
}

export default CollapsibleHeader;
