import React, { Component} from 'react';
import '../styles/JumboTron.css';
import '../styles/components.css';
import { withRouter } from './withRouter';
import { Card, Image, Col, Button, Row, Container } from "react-bootstrap";
import { animate, motion } from "framer-motion";
import { invimgdata } from "./utils/invimages.js";

class WelcomeCurtainComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
        "mainmsg":" Investing in Startups is the fastest way to build wealth",
    }
    this.size=76;
    this.duration=5;
    this.w = window.innerWidth;
    this.h = window.innerHeight;
    this.msgs = ["Investing in Startups is the fastest way to build wealth", 
                "India is the new Land Of Opportunity",
                "Make 10 times your investment by investing in startups",
                "StartupXChange Helping to bring startups to individual investors",
                "Startups and Unicorns are major contributors to India Economy growing to 10 trillion dollars in the next 10 years"
                ];
    this.remsgs = ["Construction Land Opportunities", "Plot Land Opportunities", "Farm Land Opportunites", "Bult Residential Investments", "Office, Retail & Warehouse Commercial Investments"];
    this.busmsgs= ["Sports Teams", "Hospital Chains", "Retail Chains", "Innovative Coffee & Bakery Chains", "Hotel Chains", ];
    this.rightmsgs = ["Startups = Growth", "Startup = 10-100 times return on investment", "Meta(a.k.a Facebook) - Grew 100 times from Angel Funding", "Google - Grew 10000 times from Angel Funding", "And 1000 more stories like these."];
    this.industries = ["AI - Artificial Intelligence", "Information Technology", "Aerospace", "Retail", "ECommerce", "Sustainable Farming", ]
    this.msgcounter=0;
    this.alreadytriggerd=false;
    localStorage.setItem("welcomecurtainunmounted",false);
  };

  show_my_assets = () => {
  }
  
  componentDidMount = () => {
      // this.animatedots();
      if(this.alreadytriggerd === false)
      {
        this.GenerateRandomAnimations();
        this.alreadytriggerd = true;
      }
  }
  
  animatedots = async () => {
        animate(document.getElementById("span-1"), { opacity: 0}, { duration: 0, type:"spring"})
        animate(document.getElementById("span-1"), { x:300, y:300, opacity: 1}, { duration: 5, type:"spring"})
        animate(document.getElementById("span-2"), { x:275, y:300, opacity: 1}, { duration: this.duration, type:"spring"})
        animate(document.getElementById("span-3"), { x: 300, y:300, opacity: 1}, { duration: this.duration, type:"spring"})
        animate(document.getElementById("span-4"), { x: 300, y:300, opacity: 1}, { duration: this.duration, type:"spring"})
        animate(document.getElementById("span-5"), { x: 300, y:300, opacity: 1}, { duration: this.duration, type:"spring"})
  }

  componentWillUnmount() {
    clearTimeout(localStorage.getItem("curtainanimationtimer"));
    localStorage.setItem("welcomecurtainunmounted",true);
    console.log("In generate random animation " + localStorage.getItem("welcomecurtainunmounted"));
  }

  GenerateRandomAnimations = async() => {
    console.log("0-In generate random animation " + localStorage.getItem("welcomecurtainunmounted"));
    if(localStorage.getItem("welcomecurtainunmounted") === true)
    {   
        return;
    }
    this.setState({"mainmsg":this.msgs[this.msgcounter++]});
    if(document.getElementById("mainmsgdiv"))
    {
        await animate(document.getElementById("mainmsgdiv"), {x:[-this.w,0], y:[0,0], opacity:1}, {duration: 10, type: "spring"});
    }
    if(document.getElementById("mainmsgdiv"))
    {
        animate(document.getElementById("mainmsgdiv"), {x:[0, this.w], y:[0,0], opacity:1}, {duration: 5, type: "tween"});
        let animtimer=setTimeout(this.GenerateRandomAnimations, 5000);
        localStorage.setItem("curtainanimationtimer", animtimer);
    }
    console.log(this.msgcounter);
    if(this.msgcounter >= this.msgs.length)
        this.msgcounter = 0;
  }

  render() {
    return (
      <>
{/*
       { this.w > 768 && (
          <Container fluid={true} style={{ position:"absolute"}}>
           { invimgdata.slice(0,10).map((invimg, i) => (
            <motion.div animate={{ x: [0,70+(i*40)], y: [0,90+(i*17)], opacity: 1 }} transition={{ duration:10 }} style={{ "position": "absolute" }}>
               <Image src={invimg} style={{ "max-height": "50vh", "max-width": "50%"}}/>
            </motion.div>
           ))}
           </Container>
        )}
*/}
       <Container className="px-0 bg" fluid={true} style={{ color:"#ffebcd"}}>
         <div className="text-center my-auto vh-100" id="staticmsg" style={{ }}>
            <div id="mainmsgdiv">
               <h1 id="mainmsgh1" style={{ color: "#ffebcd" }}> {this.state.mainmsg} </h1>
            </div>
            <div>
        <Row class="my-auto vh-100" style={{ "margin-top": "20px" }}>
          <Col className="card-col-p" md={4}>
            <Card className="assets-card">
              <Card.Header><h5> RealEstate Oppourtunities </h5></Card.Header>
              <Card.Img variant="top" src={"crowdseeds/assets/realestate.jpeg"}  style={{  }}/>
              <Card.Body>
                {this.remsgs.map((item, index) => (
                <motion.div whileHover={{ scale: 1.5 }}>
                    <h6> { item } </h6>
                </motion.div>
                ))}
              </Card.Body>
            </Card>
          </Col>
          <Col className="card-col-p" md={4}>
            <Card className="assets-card">
              <Card.Header><h5> EarlyStage Startup Opportunites </h5></Card.Header>
              <Card.Img variant="top" src={"crowdseeds/assets/startup.jpeg"}  style={{  }}/>
              <Card.Body>
                {this.industries.map((item, index) => (
                <motion.div whileHover={{ scale: 1.5 }}>
                    <h6> { item } </h6>
                </motion.div>
                ))}
              </Card.Body>
            </Card>
          </Col>
          <Col className="card-col-p" md={4}>
            <Card className="assets-card">
              <Card.Header><h5> Sports Investment Opportunites </h5></Card.Header>
              <Card.Img variant="top" src={"crowdseeds/assets/ipl.jpeg"}  style={{  }}/>
              <Card.Body>
                {this.busmsgs.map((item, index) => (
                <motion.div whileHover={{ scale: 1.5 }}>
                    <h6> { item } </h6>
                </motion.div>
                ))}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row class="my-auto vh-100" style={{ "margin-top": "40px" }}>
         { this.w > 768 && 
           (
            <>
{/*
             <Col class="my-auto">
                <h3> Invest in Startup Industries </h3>
                {this.industries.map((item, index) => (
                <motion.div whileHover={{ scale: 1.5 }}>
                    <h6> { item } </h6>
                </motion.div>
                ))}
             </Col>
*/}
             <Col>
                    {this.rightmsgs.map((item, i) => (
                      <motion.div whileHover={{ scale: 1.2 }}>
                        <div style={{"color": "lightblue"}}>
                            <h4>  {item}  </h4>
                        </div>
                      </motion.div>
                   ))}
                 <motion.div whileHover={{ scale: 1.4 }}>
                   <Button href="/mainpage"> Start Exploring </Button>
                  </motion.div>
               </Col>
              </>
             )}
           {this.w <= 768 &&
            (<Col style={{ maxHeight:this.h, paddingTop: "30px"}}>
                    {this.rightmsgs.map((item, i) => (
                        <div style={{ "padding": "20px", "color": "lightblue"}}>
                            <h4>  {item}  </h4>
                        </div>
                    ))}
                 <motion.div whileHover={{ scale: 1.4 }} animate={{ x: [100,0], y: [100+(5*2),0], opacity: 1 }} transition={{ duration:2 }}>
                   <Button href="/mainpage"> Start Exploring </Button>
                  </motion.div>
             </Col>)}
     </Row>
        <Row class="my-auto vh-100" style={{ "margin-top": "40px" }}>
            <div style={{"background":"orange"}}>
               <h1> We Pledge 25 Percent of Profits To India Armed Forces With Full Account Transperancy</h1> 
            </div>
        </Row>
            </div>
          </div>
       </Container>
      </>

  );
};

};

export default withRouter(WelcomeCurtainComponent);
