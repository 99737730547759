import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useNavigate, useLocation } from 'react-router-dom';
import {useEffect} from 'react';
import MainPage from "./pages/MainPage";
import NoPage from "./pages/NoPage";
import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import WelcomePage from "./pages/WelcomePage";
import LogoutPage from "./pages/LogoutPage";
import InvestmentsPage from "./pages/investmentsPage";
import UpcomingInvestmentsPage from "./pages/upcominginvestmentsPage";
import NewInvestmentPage from "./pages/newInvestmentPage";
import ViewInvestmentPage from "./pages/viewInvestmentPage";
import ViewFlowerTurbinesPage from "./pages/viewFlowerTurbinesPage";
import ViewXenaBreweryPage from "./pages/viewXenaBreweryPage";
import ViewPsyonicPage from "./pages/viewPsyonicPage.js";
import MyInvestmentsPage from "./pages/myInvestmentsPage";
import AllTradesPage from "./pages/alltradesPage";
import InfoPage from "./pages/InfoPage";
import HowStartupRoundsWorksPage from "./pages/HowStartupRoundsWorksPage";
import WhyStartupsPage from "./pages/WhyStartupsPage.js";
import AssessStartupPage from "./pages/AssessStartupPage.js";
import TinyMcePage from "./pages/TinyMcePage";
import { getWithExpiry } from './localstorageutils/localstore.js';

import './App.css';

export const LocationCheck = () => {

  const navigate = useNavigate();
  const location = useLocation();
 
  useEffect(() => {   
   
    var authinfo=getWithExpiry("userinfo");
    console.log('Location changed!', location.pathname); 
    if (authinfo === null || authinfo.name === null)
    {
        if (['/mainpage','/','/login','/logout','/signup', '/tinymce', '/info'].includes(location.pathname) === true)
        {
             return;
        };
        navigate("/login", {state:{"loginreason":"Oops - Please Login to gain access"}});
    }
    },[location, navigate]);

}

function App() {
  return (
    <BrowserRouter>
      <LocationCheck />
      <Routes>
          <Route path="/" element={<MainPage showCurtain={true}/>} />
          <Route path="/tinymce" element={<TinyMcePage/>} />
          <Route path="/mainpage" element={<MainPage showCurtain={false}/>} />
          <Route path="signup" element={<RegistrationPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="logout" element={<LogoutPage />} />
          <Route path="welcome" element={<WelcomePage />} />
          <Route path="howstartuproundworks" element={<HowStartupRoundsWorksPage />} />
          <Route path="info" element={<InfoPage />} />
          <Route path="investments" element={<InvestmentsPage />} />
          <Route path="upcominginvestments" element={<UpcomingInvestmentsPage />} />
          <Route path="newinvestment" element={<NewInvestmentPage />} />
          <Route path="viewinvestment" element={<ViewInvestmentPage type="normal"/>} />
          <Route path="viewREinvestment" element={<ViewInvestmentPage type="RE"/>} />
          <Route path="viewflowerturbines" element={<ViewFlowerTurbinesPage />} />
          <Route path="viewxenabrewery" element={<ViewXenaBreweryPage />} />
          <Route path="viewpsyonic" element={<ViewPsyonicPage />} />
          <Route path="myinvestments" element={<MyInvestmentsPage />} />
          <Route path="alltrades" element={<AllTradesPage />} />
          <Route path="whystartupinvestment" element={<WhyStartupsPage />} />
          <Route path="assessstartuppage" element={<AssessStartupPage />} />
          <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
