import React, { Component } from 'react';
import '../styles/JumboTron.css';
import '../styles/card.css';

import { withRouter } from './withRouter';
import { Image, Container, Card,Button } from "react-bootstrap";
import {animate, motion} from 'framer-motion';
import {divvariants} from './utils/MotionVariants.js';
import {invimgdata} from './utils/invimages.js';

class MainPageJumboTron extends Component {

  constructor(props) {
    super(props);
    this.state = {
        "mainmsg":"Investing in Startups is the fastest way to build wealth"
    }

    this.msgs = ["Investing in Startups is the fastest way to build wealth",
                "India is the new Land Of Opportunity",
                "Make 10 times your investment by investing in startups",
                "StartupXChange Helping to bring startups to individual investors",
                "Startups and Unicorns are major contributors to India Economy growing to 10 trillion dollars in the next 10 years"
                ];
    this.msgcounter=0;
    this.alreadytriggerd=false;
    localStorage.setItem("scrollmsg",false);
    this.w = window.innerWidth;
    this.h = window.innerHeight;
  };

  componentDidMount = () => {
      //    this.animatedots();
      /*if(this.alreadytriggerd === false)
      {
        this.GenerateRandomAnimations();
        this.alreadytriggerd = true;
      }*/
  }

  browseInvestments = () => {
    this.props.navigate('/investments');
  };
 
  

  GenerateRandomAnimations = async() => {
    this.setState({"mainmsg":this.msgs[this.msgcounter++]});
    if(document.getElementById("scrollmsgdiv"))
    {
        await animate(document.getElementById("scrollmsgdiv"), {x:[-this.w,0], y:[0,0], opacity:1}, {duration: 10, type: "spring"});
    }
    if(document.getElementById("scrollmsgdiv"))
    {
        animate(document.getElementById("scrollmsgdiv"), {x:[0, this.w], y:[0,0], opacity:1}, {duration: 5, type: "tween"});
        //let animtimer=setTimeout(this.GenerateRandomAnimations, 5000);
    }
    console.log(this.msgcounter);
    if(this.msgcounter >= this.msgs.length)
        this.msgcounter = 0;
  }

render()
{
  return (
      <div>

      {( this.w > 768 &&
       <Container fluid={true} style={{ "height": "30vh", "max-width": "100%", "z-index": "0"}}>
         { invimgdata.slice(0,20).map((invimg, i) => (
            <motion.div animate={{ x: [0,0+(i*60)], y: [-50,-50], opacity: [0, 1] }} transition={{ duration:5 }} style={{ "position": "absolute" }}>
               <Image src={invimg} style={{ "height": "30vh"}}/>
            </motion.div>
         ))}
       </Container> )}
      {( this.w < 768 &&
       <Container fluid={true} style={{ "height": "30vh", "max-width": "100%", "z-index": "0"}}>
         { invimgdata.slice(0,4).map((invimg, i) => (
            <motion.div animate={{ x: [0,0+(i*60)], y: [-50,-50], opacity: [0, 1] }} transition={{ duration:5 }} style={{ "position": "absolute" }}>
               <Image src={invimg} style={{ "height": "30vh"}}/>
            </motion.div>
         ))}
       </Container> )}

        <div class="row" style={{"padding-bottom":"100px"}}>
           <div class="col-sm-12" align="center">
             <Card bg="light" data-bs-theme="light">
                 <Card.Header> 
                    <h3> Exclusive Startup Investing Platform for the individual investor </h3>
                 </Card.Header>
             </Card>
           </div>
        </div>
        <div class="row" style={{"padding-bottom":"-20px"}}>
          <div class="col-sm-6" align="center">
            <motion.div variants={divvariants} initial="initial" animate="animate">
             <Card data-bs-theme="light" style={{ "background-color":"#fffaf0"}}>
                    <Card.Header> 
                        <h1> For Entepreneuers </h1> 
                        <h5> Raise Your Round With the Best & Trusted </h5></Card.Header>
                    <Card.Body>
                            We handle, well, everything. <br/>
                            You build the product and business, We will take care of fund raising <br/>
                    </Card.Body>
             </Card>
            </motion.div>
           </div>
          <div class="col-sm-6" align="center">
            <motion.div variants={divvariants} initial="initial" animate="animate">
             <Card data-bs-theme="light" style={{ "background-color":"#fffaf0"}}>
                    <Card.Header> 
                        <h1> For Investors </h1>
                        <h5> Invest in the exponential growth startups </h5>
                    </Card.Header>
                    <Card.Body>
                            We bring you the best startups into market  <br/>
                            Our dedicated team explores and find the best companies at early stages <br/>
                    </Card.Body>
             </Card>
            </motion.div>
           </div>
         </div>

         <div class="row" style={{"padding-bottom":"60px"}}>
             <div class="col-sm-12" align="center">
             <motion.div variants={divvariants} initial="initial" animate="animate">

                <Card bg="light" data-bs-theme="light">
                  <Card.Header as="h2">
                        <p>India's Leading Startups Investing Platform For Individual Investors</p>
                  </Card.Header>
                <Card.Body>
                    <figure class="text-right">
                      <figcaption class="blockquote-footer">
                        Transparent <cite title="Source Title">  Investing in Startups </cite>
                      </figcaption>
                      <figcaption class="blockquote-footer">
                        Full Documentation About Companies Accessible 24/7.
                      </figcaption>
                      <figcaption class="blockquote-footer">
                        24/7 Trading of shares on StartupXchange.
                      </figcaption>
                      <figcaption class="blockquote-footer">
                        Completely Transparent and Legally Structured Companies.
                      </figcaption>
                    </figure>
                <button onClick={this.browseInvestments}>
                      CheckOut Startup Investments !
                </button>
                 </Card.Body>
                </Card>
              </motion.div>
             </div>
         </div>
         <div class="row">
                <Card border="light" bg="light" data-bs-theme="light">
                        <Card.Img variant="top" class = "card-img-top" src="images/process.jpg" style={{ "max-height": "50vh", "max-width":"50%"}}/>
                        <div class = "card-img-overlay" style={{"left":"auto", "top":"auto"}}>
                            <Button variant="light" onClick={this.browseInvestments}> <h1> Get Started </h1></Button>
                        </div>
                </Card>
         </div>
    </div>
  );
 };
};

export default withRouter(MainPageJumboTron);
