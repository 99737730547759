import React, { Component} from 'react';
import '../styles/JumboTron.css';
import { connect } from 'react-redux';
import { withRouter } from './withRouter';
import { Col, Row, Card } from "react-bootstrap";
import Figure from 'react-bootstrap/Figure';
import HeadAndBodyCard from './HeadAndBodyCard';

class WhyStartupsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      "email":"",
      "password":"",
      "data":"failure",
    }
  };

  render() {
    return (
       <Card bg="light" data-bs-theme="light">
         <Card.Header>
           <Row style={{'margin':'4px', 'text-align': 'center'}}>
               <h2> Why Invest In Startup Companies </h2>
           </Row>
         </Card.Header>
         <Card.Body>
            <div>
             <Row>
               <Col md={2}> </Col>
               <Col md={8}>
                 <div>
                     <Figure.Image  height={320} src="images/startup.jpg"/>
                 </div>
               </Col>
               <Col md={2}> </Col>
             </Row>
            </div>

            <br/>
            <HeadAndBodyCard title="significant benefits of investing in startup companies." msgs={["Startup investment can be challenging and uncertain, leaving many potential investors wondering whether that’s worth the risk and if they’ll see higher returns.", "The moral of the story? In the startup world, failure is not uncommon, and investing in startups is risky. As a result of that risk, an investor can receive a great return on their investment, but they must choose the company they invest in carefully."]} />
            <br/>
            <HeadAndBodyCard title="Higher returns" msgs={["Investing early will allow investors to reap significant returns and increase their ownership stake. Startups frequently expand quickly, boosting the probability of a better return on investment. If your selected venture does excellent, it’ll yield far higher returns than the stock market."]} />
            <br/>
            <HeadAndBodyCard title="Portfolio diversification" msgs={["Even if shareholders have a substantial part of investments in public markets or other assets like real estate, allocating a portion of investments to the unlisted private sector, such as startups, is a wonderful means of diversifying and clocking better returns."]} />
            <br/>
            <HeadAndBodyCard title="Wider range of options" msgs={["Startups are not limited to a field or a sector and are developing in various industries and markets. Whether innovation, health, education, or farming, all entrepreneurs seek investment for their startups"]} />
            <br/>
            <HeadAndBodyCard title="Investment with an impact" msgs={["Startup investments present a unique chance to participate in something important, untried, possibly hyper-expanding, original, cutting-edge, and ground-breaking."]} />
            <br/>
         </Card.Body>
        </Card>
  );
};

};

const mapStateToProps = (state) => {
   return {
      authdetails: state
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      saveloginname: authname => dispatch({type:'SAVE_LOGIN_NAME', payload: authname}),
   };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WhyStartupsComponent));
