import Modal from 'react-bootstrap/Modal';
import { Row, Col, Form } from 'react-bootstrap';
import { Button } from "react-bootstrap";
import '../../styles/Modal.css';

class TradeUtils {
  static getTradeModal(thisi){

   return   (<Modal
        show={thisi.state.show_trade_modal}
        onHide={(ev) => thisi.hide_modal(ev, "trade")}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Trade Shares in Project {thisi.state.investment.invname}</Modal.Title>
        </Modal.Header>
        { (thisi.authinfo !== undefined &&
           thisi.authinfo !== null &&
           thisi.authinfo.name !== null) &&
         (<Modal.Body>
            <Row>
              <Col sm={12}>
                 Unit Value: {TradeUtils.formatCurrency(thisi.state.investment.unit_value)}
              </Col>
              <Col sm={12}>
                 Tradeable Units: {thisi.state.investment.units}
              </Col>
            </Row>
              <Form>
                <Form.Group className="mb-3" as={Row} controlId="Name">
                   <Form.Label column sm={3}>
                     Units
                   </Form.Label>
                   <Col sm={9}>
                        <Form.Control type="number" placeholder="No Of Units Interested " value={thisi.state.interestedunits} onChange={(e) => thisi.onDataChange("interestedunits", e.target.value)}/>
                    </Col>
                 </Form.Group>
                <Form.Group as={Row} className="mb-3">
                   <Form.Label column sm={3}>
                       Sell Price
                   </Form.Label>
                   <Col sm={9}>
                        <Form.Control type="number" placeholder="Sell Price" value={thisi.state.sellprice} onChange={(e) => thisi.onDataChange("sellprice", e.target.value)}/>
                   </Col>
                 </Form.Group>
               </Form>
          </Modal.Body>
         )
        }

                { (thisi.authinfo === undefined || thisi.authinfo === null || thisi.authinfo.name === null) &&
         (<Modal.Body>
            You are not signedup for showing interest in the Project. Please signup and signin before submitting your interest.
            <p className="mb-0  text-center">
                  <a href="/signup" className="text-white fw-bold">
                    Sign Up
                  </a>
            </p>

          </Modal.Body>
        )
        }
        <Modal.Footer class="modalfooterdesign">
            <Button variant="outline-light" onClick={(ev) => thisi.hide_modal(ev, "trade")}>
              Cancel
            </Button>
            <Button variant="outline-light" onClick={thisi.prepare_open_trade}>Submit To Sell</Button>
        </Modal.Footer>
      </Modal>)
  };


  static getBuyModal(thisi){

   return   (<Modal
        show={thisi.state.show_buy_modal}
        onHide={(ev) => thisi.hide_modal(ev, "buy")}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Buy Shares in Project {thisi.state.investment.invname}</Modal.Title>
        </Modal.Header>
        { (thisi.authinfo !== undefined &&
           thisi.authinfo !== null &&
           thisi.authinfo.name !== null) &&
         (<Modal.Body>
            Sell Price: {TradeUtils.formatCurrency(thisi.state.investment.sellprice)}
            <br/>
            Tradeable Units: {thisi.state.investment.units}
          </Modal.Body>
         )
        }

        { (thisi.authinfo === undefined || thisi.authinfo === null || thisi.authinfo.name === null) &&
         (<Modal.Body>
            You are not signedup to buy or sell units in the Project. Please signup and signin before submitting your interest.
            <p className="mb-0  text-center">
                  <a href="/signup" className="text-white fw-bold">
                    Sign Up
                  </a>
            </p>

          </Modal.Body>
        )
       }
       <Modal.Footer>
            <Button variant="outline-light" onClick={(ev) => thisi.hide_modal(ev, "buy")}>
              Cancel
            </Button>
            <Button variant="outline-light" onClick={thisi.perform_buy}>Submit To Buy</Button>
        </Modal.Footer>
      </Modal>)
  };

  static getConfirmInterestModal(thisi){

   return   (<Modal
        show={thisi.state.confirm_interest_modal}
        onHide={(ev) => thisi.hide_modal(ev, "confirminterest")}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Confirm Interest Shares in Project {thisi.state.investment.invname}</Modal.Title>
        </Modal.Header>
        { (thisi.authinfo !== undefined &&
           thisi.authinfo !== null &&
           thisi.authinfo.name !== null) &&
         (<Modal.Body>
            Unit Value: {TradeUtils.formatCurrency(thisi.state.investment.unit_value)}
            <br/>
            Tradeable Units: {thisi.state.investment.units}

            <Form>
                <Form.Group className="mb-3" controlId="Name">
                   <Form.Label className="text-center">
                     Name
                   </Form.Label>
                   <Form.Control type="number" placeholder="No Of Units Interested " value={thisi.state.interestedunits} onChange={(e) => thisi.onDataChange("interestedunits", e.target.value)}/>
                      </Form.Group>
            </Form>
          </Modal.Body>
         )
        }

                { (thisi.authinfo === undefined || thisi.authinfo === null || thisi.authinfo.name === null) &&
         (<Modal.Body>
            You are not signedup for showing interest in the Project. Please signup and signin before submitting your interest.
            <p className="mb-0  text-center">
                  <a href="/signup" className="text-white fw-bold">
                    Sign Up
                  </a>
            </p>

          </Modal.Body>
        )
        }
        <Modal.Footer class="modalfooterdesign">
            <Button variant="outline-light" onClick={(ev) => thisi.hide_modal(ev, "confirminterest")}>
              Cancel
            </Button>
            <Button variant="outline-light" onClick={thisi.updateuserinvestment}>Confirm Interest</Button>
        </Modal.Footer>
      </Modal>)
  };


  static showPaymentDetails(thisi){

   return  (<Modal
            show={thisi.state.payment_details_modal}
            onHide={(ev) => thisi.hide_modal(ev, "paymentdetails")}
            backdrop="static"
          >
         <Modal.Header>
            <Modal.Title>Payment Details </Modal.Title>
         </Modal.Header>
         { (thisi.authinfo !== undefined &&
            thisi.authinfo !== null &&
           thisi.authinfo.name !== null) &&
           (<Modal.Body>
              Unit Value: {TradeUtils.formatCurrency(thisi.state.investment.unit_value)}
              <br/>
                 Interested Units: {thisi.state.investment.units}
              <br/>
                 Total Payment: {TradeUtils.formatCurrency(thisi.state.investment.units * thisi.state.investment.unit_value)}
              <br/>
              <br/>
              <h5> Bank Name:  </h5>
              <br/>
              <h5> Bank Address:  </h5>
              <br/>
              <h5> Bank Account Number:  </h5>
              <br/>
              <h5> Bank IFSC Code:  </h5>
              <br/>
              <h5> Business Name:  </h5>
              <br/>
              <h5> Business Address:  </h5>
             </Modal.Body>
         )
        }
          <Modal.Footer class="modalfooterdesign">
            <Button variant="outline-light" onClick={(ev) => thisi.hide_modal(ev, "paymentdetails")}>
              Cancel
            </Button>
          </Modal.Footer>
       </Modal>
     );
  };

  static counting(num) {
    return String(num).split('').reduce(
        (count, digit) => count + 1, 0);
  }

  static formatCurrency(cur)
  {
    let formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0
    });
    let count=TradeUtils.counting(cur)
    if(count >= 8)
    {
        return formatter.format(cur/10000000) + " Crs"
    }
    else if (count  >= 6)
    {
        return formatter.format(cur/100000) + " Lacs"
    }
    else if(count >= 4)
    {
        return formatter.format(cur/1000) + " Thousands"
    }

    return formatter.format(cur)
  } 
};

export default TradeUtils;
