import React, { Component} from 'react';
import { Container } from "react-bootstrap";
import { withRouter } from './withRouter';
import { clearLocalStorage, getWithExpiry } from '../localstorageutils/localstore.js';

class Logout extends Component {

  constructor(props) {
    super(props);
    this.state = {
       displaymsg:"Logging Out",
    };
    this.authinfo=getWithExpiry("userinfo");
  };

  componentDidMount()
  {
     this.signout();
  }

  signout = () => {
        if(this.authinfo === null)
        {
            // route to login.
            return;
        }
        console.log("Trying Signing Out");
        const USER_API = '/rest/logout';
        var indata = JSON.stringify(this.authinfo);
        console.log(indata);
        fetch(USER_API, {
                  method: "POST",
                  body: indata,
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                this.setState({"displaymsg":"Successfully Logged Out"});
                clearLocalStorage("userinfo");
                this.props.navigate('/login');
         });
  };

 render() {
   return (
    <div>
      <Container className="vh-100 px-0" fluid={true}>
            <h1> {this.state.displaymsg} </h1>
      </Container>
    </div>
  );
 }

};

export default withRouter(Logout);

