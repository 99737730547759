import Container from 'react-bootstrap/Container';
import CollapsibleHeader from '../components/CollapsibleHeader.js';
import ViewFlowerTurbines from '../components/investment_details/viewFlowerTurbines.js';

const viewFlowerTurbinesPage = () => (
  <Container id="main-container" data-bs-theme="dark" fluid="true">
    <CollapsibleHeader/>
    <br/>
    <br/>

    <br/>
    <ViewFlowerTurbines/>
    <br/>

  </Container>
);

export default viewFlowerTurbinesPage;
