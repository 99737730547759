import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import store from "./redux/store/index";
window.store=store;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
       <Provider store={store}>
         <App /> 
       </Provider>
);
