import React, { Component} from 'react';
import '../../styles/JumboTron.css';
import '../../styles/Modal.css';
import '../../styles/viewInvestment.css';
import { withRouter } from '../withRouter';
import { Col, Row, Button, Container, Card  } from "react-bootstrap";
import { Form } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CurrencyUtils from './CurrencyUtils';
import { getWithExpiry } from '../../localstorageutils/localstore.js';

class ViewXenaBrewery extends Component {

  constructor(props) {
    super(props);
    this.authinfo=getWithExpiry("userinfo");
    this.state = {
     "investment": this.props.location.state,
     "interesedunits": 0,
     "show_interest_modal": false,
     "talk_to_agent_modal": false,
    }
    console.log(this.state.investment);
  };

  componentDidMount() {
  };

  onDataChange = (key, value) => {
    console.log( "onDataChange" + key + ":" + value);
  };

  saveinvestment = () => {
        if(this.authinfo === null)
        {
            // route to login.
            this.props.navigate('/login');
            return
        }
        console.log("Trying Getflowerturbines Data");
        const USER_API = '/rest/getinvestment';
        fetch(USER_API, {
                  method: "GET",
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                this.setState({"investments":data.investments});
         });
  };

  show_asset = (obj, variant) => {
    console.log(obj);
  };

  show_interest = () => {
    console.log("show_interest");
    this.setState({"show_interest_modal":true});
  };
 
  hide_interest = () => {
    console.log("hide_interest");
    this.setState({"show_interest_modal":false});
  };

  schedule_call = (obj, variant) => {
    console.log("schedule_call in a project");
  };

  render() {
   return (
     <Container className="vh-100 px-0" fluid={true}>
      <Row>
       <Col sm={6}>
         <Card bg="light" border="primary" text="dark">
            <Card.Body>
              <Card.Title>
                    {this.state.investment.name} Investment
              </Card.Title>
              <Image src={this.state.investment.doc_loc + "/main.jpeg"} style={{height:'300px', width:'300px'}}/>
            </Card.Body>
         </Card>
       </Col>
       <Col sm={3}>
        <Card bg="light" border="primary" text="dark">
            <Card.Body>
                <Card.Title>Total Valuation</Card.Title>
                <Card.Text> {CurrencyUtils.format_cur(this.state.investment.total_value)} </Card.Text>
            </Card.Body>
        </Card>
        <br/>
        <Card bg="light" border="primary" text="dark">
            <Card.Body>
                <Card.Title>Offering Type</Card.Title>
                <Card.Text> Equity </Card.Text>
            </Card.Body>
        </Card>
        <br/>
        <Card bg="light" border="primary" text="dark">
            <Card.Body>
                 <Card.Title>Price Per Share</Card.Title>
                <Card.Text> {CurrencyUtils.format_cur(this.state.investment.unit_value)} </Card.Text>
            </Card.Body>
        </Card>
       </Col>
       <Col sm={3}>
        <Card bg="light" border="primary" text="dark">
            <Card.Body>
                <Card.Title>Total Raised</Card.Title>
                <Card.Text> {CurrencyUtils.format_cur(this.state.investment.booked_units * this.state.investment.unit_value)} </Card.Text>
            </Card.Body>
        </Card>
        <br/>
        <Card bg="light" border="primary" text="dark">
            <Card.Body>
                <Card.Title>Total Investors</Card.Title>
                <Card.Text> {this.state.investment.booked_units} </Card.Text>
            </Card.Body>
        </Card>
        <br/>
        <Card bg="light" border="primary" text="dark">
            <Card.Body>
                 <Card.Title>Minimum Investment</Card.Title>
                <Card.Text> {CurrencyUtils.format_cur(this.state.investment.min_invest)} </Card.Text>
            </Card.Body>
        </Card>
        <br/>
        <Card bg="light" border="primary" text="dark">
            <Card.Body>
                 <Card.Title>
                  <div className="d-grid">
                    <Button variant="secondary" onClick={this.show_interest}>
                          Invest Now
                     </Button>
                  </div>
                 </Card.Title>
            </Card.Body>
        </Card>
       </Col>
      </Row>
    <br/>
    <Row>
      <Col md={12} style={{ background:'#F0F0F0',fontSize:'15px' }}>
        <Tabs
              defaultActiveKey="0"
              id="fill-tab-example"
              className="mb-3"
              fill
         >
            <Tab eventKey="0" title="Investment Details" style={{
              }}>
                <h3> All About Xena  </h3>
              <Card bg="light" text="dark">
                <Card.Body>
                  <Card.Text>
                  <video width="100%" height="100%" controls>
                     <source src="../images/XenaBrewery.mp4" type="video/mp4"/>
                     Your browser does not support the video tag.
                  </video>
                    </Card.Text>
                 </Card.Body>
               </Card>
              <Card bg="light" text="dark">
                <Card.Body>
                  <Card.Text>        
                    <p>
                        A place where conversations are embraced with kindred spirits from all walks of life united by a shared love for exceptional beer and amazing food. Opt for our brewery and savor the finest beers, complemented by an inviting ambiance that sets the perfect tone for your outing. Our culinary offerings and drink selection are top-notch, making us the ideal choice for a memorable experience. Cheers to quality in every aspect. But that's not all at Xena, our cocktail program is equally cutting-edge. We've harnessed the latest mixology trends and molecular techniques to create cocktails that defy convention, blending science and artistry in every glass. Visit us to witness the future of brewing, where exceptional beers, exquisite food, and avant-garde cocktails converge to create an unparalleled sensory adventure.
                    </p>
                    <br/>
                    </Card.Text>
                 </Card.Body>
               </Card>
              <br/>
              <Card bg="light" text="dark">
                <Card.Body>
                  <Card.Text>
                    <p>
                        Looking for a one-of-a-kind beer experience? Look no further than My Site! Our commitment to quality, flavor, and authenticity sets us apart from the competition. We are dedicated to delivering the best possible beer experience to our customers and are passionate about sharing our love of brewing with the world.
                     </p>
                    </Card.Text>        
                 </Card.Body>
               </Card>
             </Tab>
             <Tab eventKey="1" title="Why Invest">
               <h3> Reasons To Invest </h3>
                <Card bg="light" text="dark">
                 <Card.Body>
                   <Card.Text>        
                    <blockquote className="blockquote mb-0">
                      <p>
                        {' '}
                        Named one of India's top 10 most fundable companies by Business University Business School.{' '}
                      </p>
                     </blockquote>
                   </Card.Text>        
                  </Card.Body>
                </Card>
                <br/>
                <Card bg="light" text="dark" >
                 <Card.Body>
                  <Card.Text>        
                   <blockquote className="blockquote mb-0">
                     <p>
                        {' '}
                        Flower Turbines has successfully completed two oversubscribed StartupXChange campaigns through Reg CF and raised $9M in its last Reg A. {' '}
                     </p>
                    </blockquote>
                  </Card.Text>        
                 </Card.Body>
                </Card>
                <br/>
                <Card bg="light" text="dark">
                <Card.Body>
                  <Card.Text>        
                   <blockquote className="blockquote mb-0">
                     <p>
                        {' '}
Since our last raise, we have launched US manufacturing and sales in Lubbock, Texas, and are scaling up within the EU and negotiating additional international markets. Our already strong IP portfolio with multiple patents granted and pending in multiple countries mostly in aerodynamics is now enhanced by 10 game-changing patents in small wind turbine electronics, which could have the value of another company within the company.  
                     </p>
                    </blockquote>
                  </Card.Text>        
                </Card.Body>
                </Card>
                <br/>
                <Card bg="light" text="dark">
                <Card.Body>
                  <Card.Text>        
                   <blockquote className="blockquote mb-0">
                     <p>
                        {' '}
Right company in the right industry at the right time. New incentives for clean energy in the US, EU, and China put Flower Turbines on a rising tide.
                     </p>
                    </blockquote>
                  </Card.Text>        
                </Card.Body>
                </Card>
             </Tab>
             <Tab eventKey="2" title="About">
                    About
             </Tab>
             <Tab eventKey="3" title="Terms">
                    Terms
             </Tab>
             <Tab eventKey="4" title="Prices & Updates">
                    Prices & Updates
             </Tab>
             <Tab eventKey="5" title="Rewards">
                    Rewards
             </Tab>
             <Tab eventKey="6" title="Discussion">
                    Discussion
             </Tab>
         </Tabs>
      </Col>
    </Row>

     <Modal
        show={this.state.show_interest_modal}
        onHide={this.hide_interest}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Showing Interest In Project {this.state.investment.name}</Modal.Title>
        </Modal.Header>
        { (this.props.authdetails.authname.name !== undefined && 
           this.props.authdetails.authname.name !== null) && 
         (<Modal.Body>
            I {this.props.authdetails.authname.name} am interested in purchasing 1 unit.

            Unit Value: {this.state.investment.unit_value}

            <Form>
                <Form.Group className="mb-3" controlId="Name">
                   <Form.Label className="text-center">
                     Name
                   </Form.Label>
                   <Form.Control type="number" placeholder="No Of Units Interested " value={this.state.interestedunits} onChange={(e) => this.onDataChange("name", e.target.value)}/>
                      </Form.Group>
            </Form>
          </Modal.Body>
         )
        }

        { (this.props.authdetails.authname.name === undefined || this.props.authdetails.authname.name === null) &&
         (<Modal.Body>
            You are not signedup for showing interest in the Project. Please signup and signin before submitting your interest.
            <p className="mb-0  text-center">
                  <a href="/signup" className="text-white fw-bold">
                    Sign Up
                  </a>
            </p>

          </Modal.Body>
        )
        }
        <Modal.Footer>
            <Button variant="secondary" onClick={this.hide_interest}>
              Cancel
            </Button>
            <Button variant="primary">Submit Interest</Button>
        </Modal.Footer>
      </Modal>
    </Container>
   );
  };

};

export default withRouter(ViewXenaBrewery);
