import Container from 'react-bootstrap/Container';
import CollapsibleHeader from '../components/CollapsibleHeader.js';
import WhyStartupsComponent from '../components/WhyStartupsComponent.js';

const WhyStartupsPage = () => (
  <Container id="main-container" data-bs-theme="dark" fluid="true">
    <CollapsibleHeader/>
    <br/>
    <br/>

    <br/>
    <WhyStartupsComponent/>
    <br/>

  </Container>
);

export default WhyStartupsPage;
