class CurrencyUtils {
  static format_cur(n){
    let formatCurrency = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'INR'
    });
    return formatCurrency.format(n);
  };
};

export default CurrencyUtils;


