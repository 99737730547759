import React, { Component } from 'react';
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
var md5=require('md5');

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      "name":"",
      "email":"",
      "mobile":"",
      "fullname":"",
      "address":"",
      "aadhar":"",
      "pan":"",
      "passwordtwo":"",
      "name_pass":"",
      "role":["admin","user"],
      "investments":["One","Two"],
      "custattbs":{"one":"one"},
      "data":0,
      "datamessage":"Welcome to StartupX Registration",
      "statuscode":1000
    }
  }

  test_registration = () => {
      console.log("test_local_register");
      this.onDataChange("data",1)
  }


  get_message = () => {
    if(this.state.data === 0)
    {
            return "Welcome to StartupX Registration";
    }
    else
    {
            return "Signup Success";
    }
  }

  registration = () => {
        console.log("Trying Fetching Data");
        const USER_API = '/rest/signup';
        if(this.state["name_pass"] === this.state["passwordtwo"])
            console.log("Passwords match - continue");
        else
            console.log("Passwords do not match - raise exception");
            
        var md5hash = md5(this.state["name_pass"]);
        var ss = this.state;
        ss["name_pass"] = md5hash;
        var indata = JSON.stringify(ss);
        this.setState({"name_pass":md5hash});
        console.log(indata);
        fetch(USER_API, {
                  method: "POST",
                  body: indata,
                  headers: {
                    "Authorization": "Bearer --- invalidtoken"
                  }
         }).then(response => {
                  return response.json();
         }).then(data => {
                  console.log(data);
                  this.onDataChange("name",data.user.name);
                  this.onDataChange("email",data.user.email);
                  this.onDataChange("mobile",data.user.mobile);
                  this.onDataChange("pan",data.user.pan);
                  this.onDataChange("aadhar",data.user.aadhar);
                  this.onDataChange("statuscode", data.statuscode);
                  this.onDataChange("data",1);
                  this.onDataChange("datamessage","Registration Successful");
         });
  };

  onDataChange = (key, value) => {
    console.log( "onDataChange" + key + ":" + value);
    this.setState({[key]:value});
    console.log(this.state);
  };

 render() {
   return (
    <div>
      {this.state.data === 0 && (<Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
          <div className=""></div>
            <Card className="" bg="light" data-bs-theme="light">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-2 text-center text-uppercase ">StartupXChange  Registration</h2>
                  <br/>
                  <div className="mb-3">
                    <Form>
                      <Form.Group className="mb-3" controlId="Name">
                        <Form.Label className="text-center">
                          Name
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Name" value={this.state.name} onChange={(e) => this.onDataChange("name", e.target.value)}/>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          Email address
                        </Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={this.state.email} onChange={(e) => this.onDataChange("email",e.target.value)}/>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicMobile">
                        <Form.Label className="text-center">
                          Mobile 
                        </Form.Label>
                        <Form.Control type="mobile" placeholder="Enter mobile" value={this.state.mobile} onChange={(e) => this.onDataChange("mobile",e.target.value)}/>
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" value={this.state.name_pass} onChange={(e) => this.onDataChange("name_pass", e.target.value)}/>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" value={this.state.passwordtwo} onChange={(e) => this.onDataChange("passwordtwo", e.target.value)}/>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="secondary" onClick={this.registration}>
                          Create Account
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                      Already have an account?
                        <a href="/login" className="fw-bold">
                          Sign In
                        </a>
                      </p>
                    </div>
                    <div className="mt-3">
                    <p className="mb-0  text-center">
                         {this.state.datamessage}
                    </p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>)
   }
   { this.state.data === 1 && this.state.statuscode === 0 && (<Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <Card className="">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-2 text-center text-uppercase ">Crowd Seeds Registration Successful</h2>
                  <h2>{this.state.name}</h2>
                  <br/>
                    <div className="mt-3">
                        <p className="mb-0  text-center">
                           Already have an account?
                           <a href="/login" className="text-white fw-bold">
                              Sign In
                           </a>
                        </p>
                    </div>
                 </div>
              </Card.Body>
            </Card>
        </Col>
       </Row>
       </Container>)}

   { this.state.data === 1 && this.state.statuscode === 1 && (<Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <Card className="">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-2 text-center text-uppercase ">You Already Signed up - Please sign in</h2>
                  <h2>{this.state.name} - You Already Signed up, Please Signin </h2>
                  <br/>
                    <div className="mt-3">
                        <p className="mb-0  text-center">
                           <a href="/login" className="text-white fw-bold">
                              Sign In
                           </a>
                        </p>
                    </div>
                 </div>
              </Card.Body>
            </Card>
        </Col>
       </Row>
       </Container>)}

   { this.state.data === 1 && this.state.statuscode === 2 && (<Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <Card className="">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-2 text-center text-uppercase ">Problem Creating User</h2>
                  <h2>{this.state.name} Issues Creating User, Try Signup Again</h2>
                  <br/>
                    <div className="mt-3">
                        <p className="mb-0  text-center">
                           <a href="/signup" className="text-white fw-bold">
                              Sign Up
                           </a>
                        </p>
                    </div>
                 </div>
              </Card.Body>
            </Card>
        </Col>
       </Row>
       </Container>)}
    </div>
  )
};

};

export default Registration;
