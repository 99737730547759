import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import CollapsibleHeader from '../components/CollapsibleHeader.js';
import Logout from '../components/Logout.js';

class LogoutPage extends Component {

   logincallback = () => {
   };

   render() {
    return(
      <Container id="main-container" data-bs-theme="dark" fluid="true">
        <CollapsibleHeader/>
        <Logout/>
      </Container>
    )
   }
}

export default LogoutPage;
