import React, { Component} from 'react';
import '../styles/JumboTron.css';
import { withRouter } from './withRouter';
import { Col, Row, Card } from "react-bootstrap";

class HeadAndBodyCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  };

  render() {
    return (
        <Card bg={this.props.variantbg} text="dark" style={{margin: '2px'}}>
               <Card.Header style={{"background-color": "#244f5b"}}>
                <Row>
                <Col sm={12}>
                   <h1 class="herodesign2" style={{"color": this.props.txtcolor}}> {this.props.title} </h1>
                </Col>
                </Row>
               </Card.Header>
                <Card.Body>
              { this.props.msgs.map((msg, index) => (<p>
                            {msg}
                </p> ))}
              </Card.Body>
       </Card>
    );
  };

};

export default withRouter(HeadAndBodyCard);
