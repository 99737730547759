import Container from 'react-bootstrap/Container';
import CollapsibleHeader from '../components/CollapsibleHeader.js';
import AssessStartupComponent from '../components/AssessStartupComponent.js';

const AssessStartupPage = () => (
  <Container id="main-container" data-bs-theme="dark" fluid={true}>
    <CollapsibleHeader/>
    <br/>
    <br/>

    <br/>
    <AssessStartupComponent/>
    <br/>

  </Container>
);

export default AssessStartupPage;
