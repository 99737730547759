import React, { Component} from 'react';
import '../styles/JumboTron.css';
import { connect } from 'react-redux';
import { withRouter } from './withRouter';
import { Button, Col, Row, Card } from "react-bootstrap";
import MiniCardComponent from "./MiniCardComponent";
import {startupstages} from "./utils/InfoUtils";

class SeedStagesComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      "email":"",
      "password":"",
      "data":"failure",
    }
  };

  render() {
    return (
       <Card bg="" data-bs-theme="">
         <br/>
         <Row style={{'margin':'4px', 'text-align': 'center'}}>
           <h2> {this.props.title} </h2>
         </Row>
         <br/>
         <Row>
           {Object.keys(startupstages).map((item, index) => (
            <Col md={6}>
              <MiniCardComponent variantbg="dark" itemno={item} title={startupstages[item]["name"]} msg={startupstages[item]["msg"]} txtcolor="floralwhite" nocolor="floralwhite" mask={false}/>
            </Col>
          ))}
        </Row>
        <br/>
        <Row>
            <Col sm={10}>
            </Col>
            <Col sm={2}>
                <Button href="howstartuproundworks" variant="outline-light"> ... More </Button>
            </Col>
        </Row>
        <br/>
        </Card>
  );
};

};

const mapStateToProps = (state) => {
   return {
      authdetails: state
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      saveloginname: authname => dispatch({type:'SAVE_LOGIN_NAME', payload: authname}),
   };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeedStagesComponent));
