import React, { Component} from 'react';
import '../styles/JumboTron.css';
import { withRouter } from './withRouter';
import { Row, Col, Button, Container } from "react-bootstrap";
import { Form } from 'react-bootstrap';
import { getWithExpiry } from '../localstorageutils/localstore.js';

class NewInvestment extends Component {

  constructor(props) {
    super(props);
    this.state = {
     "investment": {"name": null, 
                    "id": null, 
                    "type":null,
                    "vertical":null,
                    "desc": null,
                    "doc_loc":null,
                    "addr": null,
                    "loc": null,
                    "short_name":null,
                    "contacts": [{"name":"name_1"},{"name":"name_2"}],
                    "total_units": 0,
                    "total_value": 0,
                    "reserved_units":0,
                    "booked_units":0,
                    "unit_value": 0,
                    "min_invest":0,
                    "cur_type": "INR",
                    "custattbs": {"attb1":"attbvalue1","attb2":"attbvalue2"}
                    }
    };
    this.authinfo=getWithExpiry("userinfo");
  };

  componentDidMount() {
  };

  onDataChange = (key, value) => {
    console.log( "onDataChange" + key + ":" + value);

    if(["total_units","total_value","reserved_units","booked_units","unit_value", "min_invest"].includes(key))
       value=Number(value)
    let investment=this.state.investment;
    let all_keys=Object.keys(investment).filter(item => item !== "custattbs");
    if (key === "contacts")
       investment["contacts"]=investment["contacts"].concat([value]);
    else if(all_keys.includes(key))
       investment[key] = value;
    else
       investment.custattbs[key]=value;
    if(key === "name")
       investment.id=value.replaceAll(" ","-");
    this.setState({"investment":investment});
    console.log(this.state);
  };

  saveinvestment = () => {
        if(this.authinfo === null)
        {
            // route to login.
            return;
        }
        console.log("Trying GetNewInvestment Data");
        const INV_API = '/rest/addinvestment';
        var indata = JSON.stringify(this.state);
        fetch(INV_API, {
                  method: "POST",
                  body: indata,
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                this.setState({"investment":data.investment});
                this.props.navigate('/viewinvestment', {state: data.investment});
         });
  };

  show_asset = (obj, variant) => {
    console.log(obj);
  };

  render() {
   return (
     <Container className="vh-100 px-0" fluid={true}>
      <Form style={{align:"center"}}>
         <Form.Group as={Row} className="mb-3" controlId="inv.NameInput">
            <Form.Label column sm="2">Name </Form.Label>
            <Col sm="6">
                <Form.Control placeholder="Enter Name" value={this.state.investment.name} onChange={(e) => this.onDataChange("name",e.target.value)}/>
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" controlId="inv.Id">
            <Form.Label column sm="2">Id</Form.Label>
            <Col sm="6">
                <Form.Control placeholder="Id" value={this.state.investment.id} readOnly/>
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" controlId="inv.desc">
            <Form.Label column sm="2">Project Description </Form.Label>
            <Col sm="6">
                <Form.Control as="textarea" rows={2} value={this.state.investment.desc} onChange={(e) => this.onDataChange("desc",e.target.value)}/>
            </Col>
          </Form.Group>
         <Form.Group as={Row} className="mb-3" controlId="inv.type">
            <Form.Label column sm="2">Project Type </Form.Label>
            <Col sm="6">
                <Form.Control as="textarea" rows={2} value={this.state.investment.type} onChange={(e) => this.onDataChange("type",e.target.value)}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="inv.vertical">
            <Form.Label column sm="2">Project Type </Form.Label>
            <Col sm="6">
                <Form.Control as="textarea" rows={2} value={this.state.investment.vertical} onChange={(e) => this.onDataChange("vertical",e.target.value)}/>
            </Col>
          </Form.Group>
         <Form.Group as={Row} className="mb-3" controlId="inv.contentfolder">
            <Form.Label column sm="2">Content Folder </Form.Label>
            <Col sm="6">
                <Form.Control placeholder="Content Folder" value={this.state.investment.doc_loc} onChange={(e) => this.onDataChange("doc_loc",e.target.value)}/>
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" controlId="inv.location">
            <Form.Label column sm="2">Location</Form.Label>
            <Col sm="6">
                <Form.Control placeholder="location" value={this.state.investment.loc} onChange={(e) => this.onDataChange("loc",e.target.value)}/>
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" controlId="inv.total_units">
            <Form.Label column sm="2">Total Units</Form.Label>
            <Col sm="6">
                <Form.Control placeholder="tunits" type="number" value={this.state.investment.total_units} onChange={(e) => this.onDataChange("total_units",e.target.value)}/>
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" controlId="inv.unit_value">
            <Form.Label column sm="2">Unit Value</Form.Label>
            <Col sm="6">
                <Form.Control placeholder="unitvalue" type="number" value={this.state.investment.unit_value} onChange={(e) => this.onDataChange("unit_value",e.target.value)}/>
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" controlId="inv.min_invest">
            <Form.Label column sm="2">Min Investment</Form.Label>
            <Col sm="6">
                <Form.Control placeholder="min_invest" type="number" value={this.state.investment.min_invest} onChange={(e) => this.onDataChange("min_invest",e.target.value)}/>
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" controlId="inv.curtype">
            <Form.Label column sm="2">Currency Type</Form.Label>
            <Col sm="6">
                <Form.Control placeholder="curtype" value={this.state.investment.cur_type} onChange={(e) => this.onDataChange("cur_type",e.target.value)}/>
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" controlId="inv.reservedunits">
            <Form.Label column sm="2">Reserved Units</Form.Label>
            <Col sm="6">
                <Form.Control placeholder="reserved_units" type="number" value={this.state.investment.reserved_units} onChange={(e) => this.onDataChange("reserved_units",e.target.value)}/>
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" controlId="inv.bookedunits">
            <Form.Label column sm="2">Booked Units</Form.Label>
            <Col sm="6">
                <Form.Control placeholder="booked_units" type="number" value={this.state.investment.booked_units} onChange={(e) => this.onDataChange("booked_units",e.target.value)}/>
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" controlId="inv.totalvalue">
            <Form.Label column sm="2">Total Value</Form.Label>
            <Col sm="6">
                <Form.Control placeholder="total_value" type="number" value={this.state.investment.total_value} onChange={(e) => this.onDataChange("total_value",e.target.value)}/>
            </Col>
         </Form.Group>
         <div className="d-grid">
             <Button variant="secondary" onClick={this.saveinvestment}>
               Add Investment 
             </Button>
         </div>
      </Form>
    </Container>
   );
  };

};

export default withRouter(NewInvestment);
