import React, { Component} from 'react';
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { withRouter } from './withRouter';
import Modal from 'react-bootstrap/Modal';
import { setWithExpiry } from '../localstorageutils/localstore.js';

var md5=require('md5');

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      "email":"",
      "name_pass":"",
      "data":"Please Enter Login Details",
      "show_interest_modal":false
    };
  };

  test_signin = () => {
     this.props.navigate('/welcome');
  };

  signin = () => {
        console.log("Trying Fetching Data");
        const USER_API = '/rest/login';
        var ss=this.state;
        ss["name_pass"]=md5(ss["name_pass"]) 
        var indata = JSON.stringify(ss);
        this.setState({"name_pass":ss["name_pass"]});
        console.log(indata);
        fetch(USER_API, {
                  method: "POST",
                  body: indata,
                  headers: {
                    "Authorization": "Bearer -- invalidtoken"
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
             if(data.statuscode === 1)
             {
                console.log("Failed Login, Wrong Password, Try Again");
                this.onDataChange("data","Failed Login, Wrong Password, Try Again");
                this.show_interest();
             }
             else if(data.statuscode === 2)
             {
                console.log("Failed Login, User Not Registered, Register Now");
                this.onDataChange("data","Failed Login, User Not Registered, Register Now");
                this.show_interest();
             }
             else {
                console.log("Success");
                this.onDataChange("data"," Login Success");
                setWithExpiry("userinfo", data.user, 3600);
                this.props.navigate('/welcome');
             }
         });
  };


  show_interest = () => {
    console.log("login show_interest");
    this.setState({"show_interest_modal":true});
  };

  hide_interest = () => {
    console.log("login hide_interest");
    this.setState({"show_interest_modal":false});
  };

  onDataChange = (key, value) => {
    console.log( "onDataChange" + key + ":" + value);
    this.setState({[key]:value});
    console.log(this.state);
  };

render() {
  return (
    <div>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
          <div className=""></div>
            <Card bg="light" data-bs-theme="light">
              <Card.Header> <h2> StartupXChange Registration </h2> </Card.Header>
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <div className="mb-3">
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          Email address
                        </Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={this.state.email} onChange={(e) => this.onDataChange("email",e.target.value)}/>
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control id="password" type="password" placeholder="Password" value={this.state.name_pass} onChange={(e) => this.onDataChange("name_pass", e.target.value)}/>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                      </Form.Group>
                      <br/>
                      <div className="d-grid">
                        <Button variant="secondary" onClick={this.signin}>
                          Sign In
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                      Dont have an account?
                        <a href="/signup" className="fw-bold">
                          Sign Up
                        </a>
                      </p>
                    </div>
                    <div className="mt-3">
                    <p className="mb-0  text-center">
                        {this.state.data}
                    </p>
                    <p className="mb-0  text-center">
                        {this.props.location.state !== null && this.props.location.state.loginreason}
                    </p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

     <Modal
        show={this.state.show_interest_modal}
        onHide={this.hide_interest}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Message </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h1> {this.state.data} </h1>
            <p className="mb-0  text-center">
                <a href="/signup" className="text-white fw-bold">
                    Sign Up
                </a>
            </p>

          </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={this.hide_interest}>
              Close
            </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
 }

};

export default withRouter(Login);
