import Container from 'react-bootstrap/Container';
import CollapsibleHeader from '../components/CollapsibleHeader.js';
import NewInvestment from '../components/newInvestment.js';

const newInvestmentPage = () => (
  <Container id="main-container" data-bs-theme="dark" fluid="true">
    <CollapsibleHeader/>
    <br/>
    <br/>

    <br/>
    <NewInvestment/>
    <br/>

  </Container>
);

export default newInvestmentPage;
