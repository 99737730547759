import Container from 'react-bootstrap/Container';
import CollapsibleHeader from '../components/CollapsibleHeader.js';
import HowStartupRoundsWorks from '../components/HowStartupRoundsWorks.js';

const HowStartupRoundsWorksPage = () => (
  <Container id="main-container" data-bs-theme="dark" fluid={true}>
    <CollapsibleHeader/>
    <br/>
    <br/>

    <br/>
    <HowStartupRoundsWorks/>
    <br/>

  </Container>
);

export default HowStartupRoundsWorksPage;
