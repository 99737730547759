import React, { Component} from 'react';
import '../styles/JumboTron.css';
import { connect } from 'react-redux';
import { withRouter } from './withRouter';
import { Col, Button, Row, Container, Card } from "react-bootstrap";
import SeedStagesComponent from "./SeedStagesComponent";
import HowToInvest from "./HowToInvest";
import {animate} from "framer-motion";

class InfoPageComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      "email":"",
      "password":"",
      "data":"failure",
    }
  };

  componentDidMount() {
    this.dosomeanimation();
  };

  dosomeanimation() {
       const showintcard = document.getElementById("show_info_card")
       animate(showintcard, { opacity: [0,1], y:[-100,0] }, { duration: 10 })
  };

  render() {
    return (
     <Container className="px-0" fluid={true}>
       <Card style={{}}>
        <Card.Body id="show_info_card">
       <Row style={{'text-align': 'left'}}>
          <Col md={6}>
          <Card bg="light" data-bs-theme="light">
             <Card.Header>
                    <h3> Why should I invest in Startups ?  </h3>
             </Card.Header>
             <Card.Body>
                    <ul>
                        <li>
                            Higher returns
                        </li>
                        <li>
                            Portfolio diversification
                        </li>
                        <li>
                            Wider range of options
                        </li>
                        <li>
                            Investment with an impact
                        </li>
                    </ul>
                    <Row>
                          <Col sm={8}>
                          </Col>
                          <Col sm={4}>
                             <Button href="whystartupinvestment" variant="outline-light"> ... More </Button>
                          </Col>
                    </Row>
             </Card.Body>
          </Card>
          </Col>
          <Col md={6}>
          <Card bg="light" data-bs-theme="light">
             <Card.Header>
                    <h3> Assessing A Startup ?  </h3>
             </Card.Header>
             <Card.Body>
                    <p>
                       Startup investment can be challenging and uncertain, leaving many potential investors wondering whether that’s worth the risk.
                    </p>
                    <p>
                        Steps to evaluate whether a startup is a good option.
                    </p>
                    <Row>
                          <Col sm={8}>
                          </Col>
                          <Col sm={4}>
                             <Button href="assessstartuppage" variant="outline-dark"> ... More </Button>
                          </Col>
                    </Row>
             </Card.Body>
          </Card>
          </Col>
       </Row>
             </Card.Body>
       </Card>
       <br/>
       <SeedStagesComponent title="Startup Evolution Stages" />
       <Row style={{'text-align': 'center'}}>
            <Card text="dark" style={{}}>
                <Card.Body>
                    StartupXChange gives you the ability to fund through public offering at any stage. 
                </Card.Body>
              </Card>
       </Row>
       <HowToInvest />
    </Container>
  );
};

};

const mapStateToProps = (state) => {
   return {
      authdetails: state
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      saveloginname: authname => dispatch({type:'SAVE_LOGIN_NAME', payload: authname}),
   };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InfoPageComponent));
