
const startupstages = {
  "1":{ "name":"Pro Seed Funding",
    "msg":"A bootstraping stage, Startup use existing resources to get the operations off the ground.",
    "icon":"1SquareFill",
    "newlines":[]
    },
  "2":{ "name":"Seed Funding",
    "msg":"During this stage, A Startup receives help in determining its final products and demographics.",
    "newlines":[]
    },
  "3":{ "name":"Series A Funding",
    "msg":"Startup is all set in terms of product development raise funds by selling equity.",
    "newlines":[]
   },
  "4":{ "name":"Series B Funding",
    "msg":"Startup raises funds by selling equity to increase Market Share.",
    "newlines":[]
   },
  "5":{ "name":"Series C Funding",
    "msg":"Startup raises funds to build products, gain market share and acquistions.",
    "newlines":[]
   },
  "6":{ "name":"IPO Funding Stages",
    "msg":"IPO is the process of offering corporate shares to the general public for the first time.",
    "newlines":[]
   },

};

const startuponboardingprocess = {
   "1": {
        "name": "Due Diligence Process",
        "msg": "StarupXChange performs a comprehensive appraisal of a business on behalf of prospective investors through StartupXChange especially to establish its assets and liabilities and evaluate its commercial potential.",
        "newlines":[]
    },
   "2": {
        "name": "Preparation",
        "msg": "Learn what the company has to offer.",
        "newlines": [1,2]
    },
   "3": {
        "name": "Learning Stage",
        "msg": "Learn what the company has to offer.",
        "newlines": [1,2]
    },
   "4": {
        "name": "Learning Stage",
        "msg": "Learn what the company has to offer.",
        "newlines": [1,2]
    },
   "5": {
        "name": "Learning Stage",
        "msg": "Learn what the company has to offer.",
        "newlines": [1,2]
    },
   "6": {
        "name": "Learning Stage",
        "msg": "Learn what the company has to offer.",
        "newlines": [1,2]
    }
};

const howtoinvest = {
    "1": {
        "name": "SIGN UP",
        "msg": "Securely Create an Account",
        "newlines":[0]
    },
    "2": {
        "name": "Browse Investments",
        "msg": "Review investment opportunities, from Startups, Collectibles, Real Estate & Others.",
        "newlines":[0]
    },
    "3": {
        "name": "Make An Investment",
        "msg": "Submit your payment and own a financial stake in a Startup or Collectible.",
        "newlines":[0]
    },
    "4": {
        "name": "Hold or Sell",
        "msg": "You can continue to invest in future rounds, hold on to your investment, or sell eligible securities on StartupXChange’s trading platform.",
        "newlines":[]

    }
};

const industries = ["AI", "Cyber Security", "Advertising and Marketing", "Agriculture", "Art", "BioTechnology", "Business to Business", "Clean Technology", "Collectibles", "Consumer Internet", "Consumer Products", "Data/Analytics", "Design", "E-Commerce", "Education", "Electronics", "Engineering", "Environment", "Events", "Fashion & Appareal", "Featured", "Film & Video", "Financial Services", "Food & Beverage", "Gaming", "Health Tech", "Hospitality", "Legal Services", "Manufacturing", "Market Research", "Media", "Mobile", "Music", "Pharmaceutical", "Professional Services", "Publishing", "Real Estate", "Retail", "Social Media", "Software Services", "Sports", "Technology", "Transportation", "Travel/Tourism", "AeroSpace", "Defence", "Finance", "Cloud Technology", "SASS Cloud"];

/* https://www.ynos.in/products/startups/index.html */

export {startupstages, howtoinvest, startuponboardingprocess, industries};
