import React, { Component} from 'react';
import '../styles/JumboTron.css';
import { withRouter } from './withRouter';
import { Button, Container, Card  } from "react-bootstrap";
import { Table } from 'react-bootstrap';
import TradeUtils from './utils/TradeUtils';
import GeneralUtils from './investment_details/GeneralUtils';
import { getWithExpiry } from '../localstorageutils/localstore.js';

class MyInvestmentsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      "investments":[],
      "userinvestments":[],
      "opentrades":[],
      "investment":{"username":"", "unit_value":0},
      "interestedunits":0,
      "sellprice":0,
      "status":0,
      "displaymsg": "Loading Investments",
      "contains_page":["viewflowerturbines","viewxenabrewery"],
      "show_trade_modal":false,
      "confirm_interest_modal":false,
      "payment_details_modal":false,
      "modal_display_msg":""
    };
    this.authinfo=getWithExpiry("userinfo");
  };

  componentDidMount() {
        this.getuserinvestments();
        GeneralUtils.get_open_trades(this);
  };

  getuserinvestments = () => {
        if(this.authinfo === null)
        {
            // route to login.
            return;
        }
        console.log("Trying GetUserInvestments Data");
        const USER_API = '/rest/getuserinvestments';
        this.setState({"status":1});
        var indata = JSON.stringify({"user": this.authinfo.email});
        fetch(USER_API, {
                  method: "POST",
                  body: indata,
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                if(data.investments.length === 0)
                    this.setState({"displaymsg":"No Open User Investments Available, Check back later"});
                this.setState({"status":0});
                this.setState({"investments":data.investments});
                this.setState({"userinvestments":data.user_investments});
         });
   };

   updateuserinvestment = () => {
        if(this.authinfo === null)
        {
            // route to login.
            return;
        }
        console.log("Trying SaveUserInvestment Data");
        const INV_API = '/rest/saveuserinvestment';
        let inv=this.state.investment;
        inv.state="confirminterest";
        inv.units = this.state.interestedunits;
        var indata = JSON.stringify({"userinvestment":inv});
        fetch(INV_API, {
                  method: "POST",
                  body: indata,
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                this.setState({"investment":inv});
                this.setState({"confirm_interest_modal":false});
         });
   }; 

   removeuserinvestment = (ev, inv) => {
        if(this.authinfo === null)
        {
            // route to login.
            return;
        }
        console.log("Trying DeleteUserInvestment Data");
        const INV_API = '/rest/deluserinvestment';
        this.setState({"investment":inv});
        var indata = JSON.stringify({"userinvestment":inv});
        fetch(INV_API, {
                  method: "POST",
                  body: indata,
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                let uinvs = this.state.userinvestments;
                uinvs =  uinvs.filter(item => item.id !== this.state.investment.id);
                this.setState({"userinvestments":uinvs});
         });
   };

   paiduserinvestment = (ev, inv) => {
        if(this.authinfo === null)
        {
            // route to login.
            return;
        }
        console.log("Trying Bookeduserinvestment Data");
        const INV_API = '/rest/bookuserinvestment';
        this.setState({"investment":inv});
        var indata = JSON.stringify({"userinvestment":inv});
        fetch(INV_API, {
                  method: "POST",
                  body: indata,
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                let uinvs = this.state.userinvestments;
                uinvs =  uinvs.filter(item => item.id !== this.state.investment.id);
                uinvs.push(data.user_investment);
                this.setState({"investment":data.user_investment});
                this.setState({"userinvestments":uinvs});
         });
   };

   getinvtype = (userinv) => {
        var invs=this.state.investments.filter(item => item.id === userinv.invname);
        console.log(invs); 
        if(invs !== undefined && invs !== null && invs.length > 0)
        {
            return invs[0].type;
        }
        return "itszero";
   };

   updateuserinvestment_1 = (inv) => {
        if(this.authinfo === null)
        {
            // route to login.
            return;
        }
        console.log("Trying SaveUserInvestment Data");
        const INV_API = '/rest/saveuserinvestment';
        var indata = JSON.stringify({"userinvestment":inv});
        fetch(INV_API, {
                  method: "POST",
                  body: indata,
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
         });
   };

   prepare_open_trade = () => {
        var variant=this.state.investment;
        if (this.state.interestedunits === variant.units)
        {
            this.removeuserinvestment(null, variant);
            variant.state="opentrade";
            variant.sellprice=this.state.sellprice;
            GeneralUtils.addopentrade(this, variant);
        } 
        else if (this.state.interestedunits < variant.units)
        {
            variant.units=variant.units-this.state.interestedunits;
            this.updateuserinvestment_1(variant);
            var variant1= { ...variant } ;
            variant1.units=this.state.interestedunits;
            variant1.sellprice=this.state.sellprice;
            variant1.state="opentrade";
            GeneralUtils.addopentrade(this, variant1);
        }
        else
        {   
            console.log("Error - Should Never Happen in prepare open trades");
            return;
        } 
   };

  show_asset = (obj, variant) => {
    console.log(variant);
    let inv_name='view' + variant.name.replace(" ","").toLowerCase();
    if(this.state.contains_page.includes(inv_name))
    {
         this.props.navigate('/view' + variant.name.replace(" ","").toLowerCase(), {state: variant});
    }
    else
    {
         this.props.navigate('/viewinvestment', {state: variant});
    }
  };

  arrayChunk = (arr, n) => {
    const array = arr.slice();
    const chunks = [];
    while (array.length) chunks.push(array.splice(0, n));
    return chunks;
  };

  show_modal = (ev, inv, modal_name) => {
    if(modal_name === "trade")
    {
        console.log("show_trade_modal");
        /* modify trade investment variable to be used by modal */
        this.setState({"investment":inv});
        this.setState({"show_trade_modal":true});
     }
     else if(modal_name === "confirminterest")
     {
        console.log("confirm_interest_modal");
        /* modify trade investment variable to be used by modal */
        this.setState({"investment":inv});
        this.setState({"confirm_interest_modal":true});
     }
     else if(modal_name === "paymentdetails")
     {
        console.log("payment_details_modal");
        this.setState({"investment":inv});
        this.setState({"payment_details_modal":true});
     }
  };

  hide_modal = (ev, modal_name) => {
    if(modal_name === "trade")
    {
        console.log("hide_trade_modal");
        this.setState({"show_trade_modal":false});
     }
     else if(modal_name === "confirminterest")
     {
        console.log("hide_confirminterest_modal");
        this.setState({"confirm_interest_modal":false});
     }
     else if(modal_name === "paymentdetails")
     {
        console.log("hide_payment_details_modal");
        this.setState({"payment_details_modal":false});
     }
  };

  open_trade_modal = (ev, variant) => {
    console.log("Trade Investment Modal ");
    this.setState({"investment":variant});
    this.setState({"show_trade_modal":true});
  };

  confirminterest = () => {
    console.log("Confirm Investment Interest Modal ");
    this.setState({"confirm_interest_modal":false});
  };

  onDataChange = (key, value) => {
    if(key === "interestedunits")
    {
        if( Number(value) > this.state.investment.units)
        {
           this.setState({"modal_display_msg":"You only have " + this.state.investment.units + " to trade,"});
        }
        else
        {
           this.setState({"modal_display_msg":""});
           this.setState({"interestedunits":Number(value)});
        }
    }
    else if(key === "sellprice")
    {
        this.setState({"sellprice":Number(value)});
    }
  };

  render() {
   return (
    <div>
     { TradeUtils.getTradeModal(this) }
     { TradeUtils.getConfirmInterestModal(this) }
     { TradeUtils.showPaymentDetails(this) }
     { (this.state.userinvestments !== undefined && this.state.userinvestments.length !== 0) && 
       (<Container className="vh-100 px-0" fluid={true}>
        <Card bg="light" border="primary" text="dark" style={{margin: '2px'}}>
        <Card.Header> {this.authinfo.name}'s Invested Shares </Card.Header>
        <Card.Body>
            <Table striped bordered hover size="sm">
             <thead>
                <tr>
                    <td> Investment Name </td>
                    <td> Units </td>
                    <td> Unit Value </td>
                    <td> Action </td>
                </tr>
             </thead>
             {this.state.userinvestments.filter(inv => inv.state === "booked").map(( listvalue, index ) => {
                return (
                    <tr key={index}>
                     <td>{listvalue.invname}</td>
                     <td>{listvalue.units}</td>
                     <td>{TradeUtils.formatCurrency(listvalue.unit_value)}</td>
                     <td><Button onClick={(ev) => this.open_trade_modal(ev, listvalue)}> Trade </Button></td>
                    </tr>
                );
            })}
            </Table>
          </Card.Body>
        </Card>
        <br/>
        <Card bg="light" border="primary" text="dark" style={{margin: '2px'}}>
        <Card.Header> {this.authinfo.name}'s Interested Investments </Card.Header>
        <Card.Body>
            <Table striped bordered hover size="sm">
             <thead>
                <tr>
                    <td> Investment Name </td>
                    <td> Units </td>
                    <td> Unit Value </td>
                    <td> Action </td>
                </tr>
             </thead>
             {this.state.userinvestments.filter(inv => ((inv.state === "interest" || inv.state === "confirminterest") && this.getinvtype(inv) === "active")).map(( listvalue, index ) => {
                return (
                    <tr key={index}>
                      <td>{listvalue.invname}</td>
                      <td>{listvalue.units}</td>
                      <td>{TradeUtils.formatCurrency(listvalue.unit_value)}</td>
                      {listvalue.state === "interest" && <td><Button onClick={(ev) => this.show_modal(ev, listvalue, "confirminterest")}> Confirm Interest </Button> <Button onClick={(ev) => this.removeuserinvestment(ev, listvalue)}> Cancel Interest </Button></td>}
                      {listvalue.state === "confirminterest" && <td><Button onClick={(ev) => this.show_modal(ev, listvalue, "paymentdetails")}> Payment Details  </Button> <Button onClick={(ev) => this.removeuserinvestment(ev, listvalue)}> Cancel Interest </Button> <Button onClick={(ev) => this.paiduserinvestment(ev, listvalue)}> Paid </Button></td>}
                    </tr>
                );
            })}
            </Table>
          </Card.Body>
        </Card>
        <br/>
        <Card bg="light" border="primary" text="dark" style={{margin: '2px'}}>
         <Card.Header> {this.authinfo.name}'s Open Trades  </Card.Header>
         <Card.Body>
            <Table striped bordered hover size="sm">
             <thead>
                <tr>
                    <td> Investment Name </td>
                    <td> Units </td>
                    <td> Unit Value </td>
                    <td> Action </td>
                </tr>
             </thead>
             {this.state.opentrades.filter(inv => inv.state === "opentrade").map(( listvalue, index ) => {
                return (
                    <tr key={index}>
                     <td>{listvalue.invname}</td>
                     <td>{listvalue.units}</td>
                     <td>{TradeUtils.formatCurrency(listvalue.unit_value)}</td>
                     <td><Button> Buy </Button></td>
                    </tr>
                );
             })}
            </Table>
           </Card.Body>
         </Card>
        <br/>
        <Card bg="light" border="primary" text="dark" style={{margin: '2px'}}>
         <Card.Header> {this.authinfo.name}'s Completed Trades  </Card.Header>
         <Card.Body>
            <Table striped bordered hover size="sm">
             <thead>
                <tr>
                    <td> Investment Name </td>
                    <td> Units </td>
                    <td> Unit Value </td>
                    <td> Action </td>
                </tr>
             </thead>
             {this.state.userinvestments.filter(inv => inv.state === "closedtrade").map(( listvalue, index ) => {
                return (
                    <tr key={index}>
                     <td>{listvalue.invname}</td>
                     <td>{listvalue.units}</td>
                     <td>{TradeUtils.formatCurrency(listvalue.unit_value)}</td>
                     <td><Button> Trade </Button></td>
                    </tr>
                );
            })}
            </Table>
           </Card.Body>
         </Card>
       </Container>)
     }
     </div>
   );
  };

};

export default withRouter(MyInvestmentsComponent);
