import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import CollapsibleHeader from '../components/CollapsibleHeader.js';
import MainPageJumboTron from '../components/MainPageJumboTron.js';
import WelcomeCurtainComponent from '../components/WelcomeCurtainComponent.js';
import { animate } from 'framer-motion';
import Ratio from 'react-bootstrap/Ratio';

class MainPage extends Component {

  constructor(props) {
     super(props);

     if (this.props.showCurtain === true)
     { 
        // Set initial state
        this.state = {
           "showCurtain": true
        }
        setTimeout(this.raiseCurtain, 50000);
      }
      else
      {
        // Set initial state
        this.state = {
           "showCurtain": false
        }
      }
  }

  componentDidMount() {
  }

  raiseCurtain = async () => {
        if(document.getElementById("curtain"))
            await animate(document.getElementById("curtain"), { y: [0, -1024], }, { duration: 1 })
        this.setState({"showCurtain":false});
        if(document.getElementById("main-div"))
            await animate(document.getElementById("main-div"), { x: [-1024, 0]}, { duration: 1 })
  }

  setShowCurtain = (val) => {
        this.setState({"showCurtain":false});
  }

  render() {
   return (
    <div>
     {this.state.showCurtain === true &&(
        <div id="curtain"> 
          <WelcomeCurtainComponent raiseCurtain={this}/>
        </div>
     )}

     <div id="main-div">
      {this.state.showCurtain === false && ( 
        <Container id="main-div-div" data-bs-theme="dark" style={{ "z-index": "0" }} fluid={true}>
       <CollapsibleHeader/>
       <br/>
       <br/>

       <br/>
       <MainPageJumboTron/>
       <br/>
       </Container>
       )}
      </div>
    </div>
   );
  }
};

export default MainPage;
