import React, { Component} from 'react';
import '../styles/JumboTron.css';
import { connect } from 'react-redux';
import { withRouter } from './withRouter';
import { Col, Row, Card } from "react-bootstrap";
import  * as Icon  from 'react-bootstrap-icons';
import { motion } from 'framer-motion';
import {divvariants} from './utils/MotionVariants.js';

class MiniCardComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
        "expand":false
    }
  };

  expand_card = (b) => {
    this.setState({"expand":b})
  }


  getIcon = (item) => {
    if(item === "1")
        return <Icon.Icon1SquareFill color={this.props.nocolor} size={30}/>;
    else if(item === "2")
        return <Icon.Icon2SquareFill color={this.props.nocolor} size={30}/>;
    else if(item === "3")
        return <Icon.Icon3SquareFill color={this.props.nocolor} size={30}/>;
    else if(item === "4")
        return <Icon.Icon4SquareFill color={this.props.nocolor} size={30}/>;
    else if(item === "5")
        return <Icon.Icon5SquareFill color={this.props.nocolor} size={30}/>;
    else if(item === "6")
        return <Icon.Icon6SquareFill color={this.props.nocolor} size={30}/>;
  }


  render() {
    return (
       <motion.div variants={divvariants} initial="initial" animate="animate" style={{ padding:"20px"}}>
        <Card bg={this.props.variantbg} data-bs-theme="dark" style={{ "background-image": "repeating-linear-gradient(40deg, rgb(87, 87, 162), #645437, blue 4px)" }}>
               <Card.Header onMouseEnter={() => this.expand_card(true)} onMouseLeave={() => this.expand_card(false)} style={{"background-color": this.props.bgcolor}}>
                <Row>
                <Col md={1}>
                   {this.getIcon(this.props.itemno)}
                </Col>
                <Col md={10}>
                   <h6 class="herodesign1" style={{"color": this.props.txtcolor}}> {this.props.title} </h6>
                </Col>
                </Row>
               </Card.Header>

            <Card.Body>
            { (this.props.mask === true && this.state.expand === true) && (
                 <p> {this.props.msg} </p>
             )
            }
            { (this.props.mask === false) && (
                 <p> {this.props.msg} </p>
             )
            }

            {this.props.newlines !== undefined && this.props.newlines.map((item,i) => (
               <br/>
            ))}
            </Card.Body>
       </Card>
     </motion.div>
    );
  };

};

const mapStateToProps = (state) => {
   return {
      authdetails: state
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      saveloginname: authname => dispatch({type:'SAVE_LOGIN_NAME', payload: authname}),
   };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MiniCardComponent));
