import React, { Component} from 'react';
import '../styles/JumboTron.css';
import { connect } from 'react-redux';
import { withRouter } from './withRouter';
import { Row, Card } from "react-bootstrap";
import Figure from 'react-bootstrap/Figure';

class HowStartupRoundsWorks extends Component {

  constructor(props) {
    super(props);
    this.state = {
      "email":"",
      "password":"",
      "data":"failure",
    }
  };

  render() {
    return (
       <Card bg="light" data-bs-theme="light">
         <Card.Header>
           <Row style={{'margin':'4px', 'text-align': 'center'}}>
               <h2> How Startup Round Works </h2>
           </Row>
         </Card.Header>
         <Card.Body>
            <div>
                <Figure.Image width={786} height={520} src="images/indian_rupee.jpg"/>
            </div>

            <br/>
            <p>
            Many startups aspire to be the next big thing, but every business must start small. If you are an investor, you have the potential to be part of the initial push to get a project off the ground. But the odds are long: while about 80% of small businesses survive their first year, only half reach the five-year mark, according to government statistics.
            </p>

            <p>
The moral of the story? In the startup world, failure is not uncommon, and investing in startups is risky. As a result of that risk, an investor can receive a great return on their investment, but they must choose the company they invest in carefully.
            </p>
            <p>
However, before you explore the companies on StartupXChange and invest, it’s important to have a framework of funding rounds to understand how startups finance their business and grow. This knowledge can help you place the company’s metrics and growth strategies into context.
            </p>

            <p>
If you’re looking for clarity on how startup funding rounds work, then you’ve come to the right place. So let’s break it down.
            </p>

            <h3> Pre-Seed Round Funding </h3>
            <p>
In the earliest stage of a company’s development, founders are just beginning to make their operation a reality. At this point, they may have a small team developing a business plan, marketing strategy and, if applicable, a prototype, but they often haven’t generated any revenue yet. Pre-Seed funding typically comes from the founders themselves, as well as friends, family members, and of course crowdfunding (you know we’re gonna plug it). The amount of capital raised during this round can vary; some businesses will raise $10,000, while others will raise up to $1M.
            </p>
            <h3> Seed Round Funding </h3>
            <p>
The Seed round represents the first formal round of funding. As the name suggests, these early investments represent the “seed” from which the business will grow. With this funding, companies can go on to earn traction with consumers, showcase their product/market fit, or develop other tangible signs of growth and promise that will be necessary to raise future rounds of funding (more on that later).
            </p>
            <p>

Interestingly enough, as equity crowdfunding has grown in popularity, the number of seed deals from venture capitalists and angel investors has decreased.
            </p>
            <p>

Source: <a href="https://pitchbook.com/news/articles/21-charts-showing-current-trends-in-us-venture-capital">> https://pitchbook.com/news/articles/21-charts-showing-current-trends-in-us-venture-capital </a>
            </p>
            <p>

At the seed funding stage, companies are still little more than an idea, and investing is a risky proposition. However, the potential upside on your investment is much higher because equity generally is less expensive the earlier you invest in the business.
            </p>
            <p>

With each new funding round, companies will generally look to increase the price per share of their offering, usually resulting in higher valuations during each subsequent round.  This is called a risk premium, and rewards earlier investors with a better price per share in exchange for making investments while the companies are still at a much riskier development stage. This all means that the earlier you invest, the more likely you are to get a lower price per share, in comparison to future rounds.
            </p>
            <p>

Companies often raise around $1M-$2M in a seed funding round. Both pre-seed and seed funding rounds are akin to what companies can raise under Regulation Crowdfunding on StartupXChange.
            </p>

            <h3> Series A </h3>
            <p>
At this point, businesses usually have a decent amount of users, incoming revenue, or other key performance indicators. However, in most cases the startup still isn’t profitable and needs to continue raising capital.
            </p>
            <p>

The company needs to show investors their vision and strategy that will create long-term profits in order to raise their Series A, a round which can range anywhere between $3M-$15M, though, especially in the past few years, some tech startups have raised $25M+ in a Series A.
            </p>
            <p>

For investors in the Seed round, the Series A means that there are now more investors in the business and more shares have been issued in the company. This also means that the seed investor owns a smaller percentage of the entire company.
            </p>
            <p>

This is a process called dilution (as more shares are issued to new investors, the seed investor’s shares are now a smaller percentage of the total). This isn’t a bad thing for early investors because while you may own a smaller percentage of the company, if the company is growing it usually means investors are investing at a higher price, signaling that the value of your shares may have increased.
            </p>

            <h3> Series B </h3>
            <p>
After leveraging the Series A funding, businesses become well-established. They have a growing user base and foothold in the market, but they often need funding to improve operations and achieve scale. So they raise capital once again with a Series B, which generates around $20M or more in funding.
            </p>
            <p>

In a Series B round, many new investors will participate, but sometimes you will see some of the same investors that participated in the Series A invest again in the Series B. Investors sometimes invest in a later round to maintain their level of ownership in a company (often through pro rata rights) and avoid their ownership percentage being diluted by new shareholders.
            </p>

            <h3> Series C, D & Beyond </h3>
            <p>
After this point, businesses are financially successful and have achieved a level of market popularity. Future rounds of funding are focused on further scaling the business, entering new markets, dominating said markets, and acquisitions.

            </p>
            <p>
Generally speaking, the level of risk has decreased compared to previous funding rounds because the company has now proven its success. As a result, later funding rounds greatly increase in size (hundreds of millions of dollars) as investors (and the company) swing for the fences to try and achieve market dominance.
            </p>

            <h3> Exiting Your Investment </h3>

            <p>
Traditionally, an IPO (Initial Public Offering) marks the first time a private company offers shares to the public. An IPO is an opportunity for the company to continue raising money while allowing millions of ordinary people to buy its stock. It’s also an early investor’s first chance to sell their stock for cash and exit their investment (hopefully at a profit). The other historical alternative for an exit is if the company is acquired.
            </p>
            <p>
However, equity crowdfunding changes all of that. Not only can the public now invest in companies before the IPO, but one day we hope they will be able to exit their investment sooner too (as IPOs and acquisitions can often take anywhere from 5-10 years before they occur).
            </p>
            <p>
At StartupXChange, we launched StartupXChange Marketplace*, the hub for our new bulletin-board where you can tell fellow investors when you’d like to buy or sell shares from prior funding rounds on any crowdfunding platform.
            </p>
            <p>
This means StartupXChange provides an additional channel for investors to potentially exit their investments through our trading platform, rather than being restricted to historical exit channels like IPO or acquisition.** Our goal is to say goodbye to the 5-10 year waiting period, and say hello to the future of investing.
            </p>
         </Card.Body>
        </Card>
  );
};

};

const mapStateToProps = (state) => {
   return {
      authdetails: state
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      saveloginname: authname => dispatch({type:'SAVE_LOGIN_NAME', payload: authname}),
   };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HowStartupRoundsWorks));
