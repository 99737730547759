import Container from 'react-bootstrap/Container';
import CollapsibleHeader from '../components/CollapsibleHeader.js';
import ViewPsyonic from '../components/investment_details/viewPsyonic.js';

const viewPsyonic = () => (
  <Container id="main-container" data-bs-theme="dark" fluid="true">
    <CollapsibleHeader/>
    <br/>
    <br/>

    <br/>
    <ViewPsyonic/>
    <br/>

  </Container>
);

export default viewPsyonic;
