import Modal from 'react-bootstrap/Modal';
import CurrencyUtils from './CurrencyUtils.js';
import { Form } from 'react-bootstrap';
import { Row, Col, Card, Button } from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import '../../styles/Modal.css';

class GeneralUtils {
  static getShowInterestModal(thisi){

   return   (<Modal
        show={thisi.state.show_interest_modal}
        onHide={thisi.hide_interest}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Showing Interest In Project {thisi.state.investment.name}</Modal.Title>
        </Modal.Header>
        { (thisi.authinfo !== undefined &&
           thisi.authinfo !== null &&
           thisi.authinfo.name !== null) &&
         (<Modal.Body>
            I {thisi.authinfo.name} am interested in purchasing 1 unit.

            Unit Value: {CurrencyUtils.format_cur(thisi.state.investment.unit_value)}

            <Form>
                <Form.Group className="mb-3" controlId="Name">
                   <Form.Label className="text-center">
                     Name
                   </Form.Label>
                   <Form.Control type="number" placeholder="No Of Units Interested " value={thisi.state.interestedunits} onChange={(e) => thisi.onDataChange("interestedunits", e.target.value)}/>
                      </Form.Group>
            </Form>
          </Modal.Body>
         )
        }

        { (thisi.authinfo === undefined || thisi.authinfo === null || thisi.authinfo.name === null) &&
         (<Modal.Body>
            You are not signedup for showing interest in the Project. Please signup and signin before submitting your interest.
             <p className="mb-0  text-center">
                   <a href="/signup" className="fw-bold">
                     Sign Up
                  </a>
            </p>
           </Modal.Body>
          )
         }
        <Modal.Footer>
            <Button variant="secondary" onClick={thisi.hide_interest}>
              Cancel
            </Button>
            { (thisi.authinfo !== undefined &&  thisi.authinfo !== null &&  thisi.authinfo.name !== null) &&
                (<Button variant="primary" onClick={thisi.saveuserinvestment}>Submit Interest</Button>)
            }
        </Modal.Footer>
      </Modal>)
  };

  static getMainPanelForInvestment(thisi) {
   return (
      <Row>
       <Col sm={6}>
         <Card bg="light" border="primary" text="dark">
              <Card.Header as="h3">
                    {thisi.state.investment.name} Investment
              </Card.Header>
            <Card.Body>
              <Image src={thisi.state.investment.doc_loc + "/main.jpeg"}/>
            </Card.Body>
         </Card>
       </Col>
       <Col sm={3}>
        <Card bg="light" border="primary" text="dark">
              <Card.Header as="h3">
                   Total Valuation
              </Card.Header>
            <Card.Body>
                <Card.Text> {CurrencyUtils.format_cur(thisi.state.investment.total_value)} </Card.Text>
            </Card.Body>
        </Card>
        <br/>
        <Card bg="light" border="primary" text="dark">
              <Card.Header as="h3">
                Offering Type
              </Card.Header>
            <Card.Body>
                <Card.Text> Equity </Card.Text>
            </Card.Body>
        </Card>
        <br/>
        <Card bg="light" border="primary" text="dark">
              <Card.Header as="h3">
                 Price Per Share
              </Card.Header>
            <Card.Body>
                <Card.Text> {CurrencyUtils.format_cur(thisi.state.investment.unit_value)} </Card.Text>
            </Card.Body>
        </Card>
       </Col>
       <Col sm={3}>
        <Card bg="light" border="primary" text="dark">
             <Card.Header as="h3">
                Total Raised
             </Card.Header>
            <Card.Body>
                <Card.Text> {CurrencyUtils.format_cur(thisi.state.investment.booked_units * thisi.state.investment.unit_value)} </Card.Text>
            </Card.Body>
        </Card>
        <br/>
        <Card bg="light" border="primary" text="dark">
             <Card.Header as="h3">
                Total Investors
             </Card.Header>
            <Card.Body>
                <Card.Text> {thisi.state.investment.booked_units} </Card.Text>
            </Card.Body>
        </Card>
        <br/>
        <Card bg="light" border="primary" text="dark">
             <Card.Header as="h3">
                 <Card.Title>Minimum Investment</Card.Title>
             </Card.Header>
            <Card.Body>
                <Card.Text> {CurrencyUtils.format_cur(thisi.state.investment.min_invest)} </Card.Text>
            </Card.Body>
        </Card>
        <br/>
        <Card bg="light" border="primary" text="dark">
            <Card.Body>
                 <Card.Title>
                  <div className="d-grid">
                    <Button variant="secondary" onClick={thisi.show_interest}>
                          Invest Now
                     </Button>
                  </div>
                 </Card.Title>
            </Card.Body>
        </Card>
       </Col>
      </Row>)
   };

   static saveuserinvestment(thisi) {
        if(thisi.authinfo === null)
        {
            // route to login.
            thisi.props.navigate('/login');
            return
        }
        console.log("Trying GetNewInvestment Data");
        const INV_API = '/rest/adduserinvestment';

        var indata = JSON.stringify({'userinvestment':{'username': thisi.authinfo.email, 'invname': thisi.state.investment.id, 'id': thisi.authinfo.email + "--" + thisi.state.investment.name + "--interest", 'desc': '',state: 'interest', 'units': Number(thisi.state.interestedunits), 'unit_value': thisi.state.investment.unit_value, 'custattbs':{}}});
        fetch(INV_API, {
                  method: "POST",
                  body: indata,
                  headers: {
                        "Authorization": "Bearer " + ((thisi.authinfo === null) ? "invaliduser invalidtoken": thisi.authinfo.email + " " + thisi.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                thisi.setState({"show_interest_modal":false});
         });
  };

   static get_open_trades(thisi) {
        if(thisi.authinfo === null)
        {
            // route to login.
            thisi.props.navigate('/login');
            return
        }
        console.log("Trying GetOpenTrades Data");
        const USER_API = '/rest/getopentradebyuser';
        thisi.setState({"status":1});
        var indata = JSON.stringify({"user": thisi.authinfo.email});
        fetch(USER_API, {
                  method: "POST",
                  body: indata,
                  headers: {
                        "Authorization": "Bearer " + ((thisi.authinfo === null) ? "invaliduser invalidtoken": thisi.authinfo.email + " " + thisi.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                thisi.setState({"status":0});
                thisi.setState({"investments":data.investments});
                thisi.setState({"opentrades":data.open_trades});
         });
   }

   static addopentrade(thisi, opentrade) {
        if(thisi.authinfo === null)
        {
            // route to login.
            thisi.props.navigate('/login');
            return
        }
        console.log("Trying AddOpenTrade");
        const INV_API = '/rest/addopentrade';

        var indata = JSON.stringify({'opentrade':opentrade});
        fetch(INV_API, {
                  method: "POST",
                  body: indata,
                  headers: {
                        "Authorization": "Bearer " + ((thisi.authinfo === null) ? "invaliduser invalidtoken": thisi.authinfo.email + " " + thisi.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                thisi.setState({"show_trade_modal":false});
         });
  };
};

export default GeneralUtils;
