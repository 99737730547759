import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import CollapsibleHeader from '../components/CollapsibleHeader.js';
import Registration from '../components/Registration.js';

class RegistrationPage extends Component {
   logincallback = () => {
   };
  
   render() {
    return(
      <Container id="main-container" data-bs-theme="dark" fluid="true">
        <CollapsibleHeader/>

        <Registration/>

      </Container>
    )
   }
}

export default RegistrationPage;
