import React, { Component } from "react";
import TinyMceEditor from '../components/TinyMceEditor.js';
import { animate } from 'framer-motion';


class MainPage extends Component {

  constructor(props) {
     super(props);

  }

  componentDidMount() {
  }

  render() {
   return (
    <div>
        <TinyMceEditor placeholder="Testing...."/>
    </div>
   );
  }
};

export default MainPage;
