import Container from 'react-bootstrap/Container';
import CollapsibleHeader from '../components/CollapsibleHeader.js';
import WelcomePageComponent from '../components/WelcomePageComponent.js';

const WelcomePage = () => (
  <Container id="main-container" data-bs-theme="dark" fluid="true">
    <CollapsibleHeader/>
    <br/>
    <br/>

    <br/>
    <WelcomePageComponent/>
    <br/>

  </Container>
);

export default WelcomePage;
