import React, { Component} from 'react';
import '../styles/JumboTron.css';
import '../styles/Modal.css';
import '../styles/viewInvestment.css';
import { withRouter } from './withRouter';
import { Col, Row, Button, Container, Card  } from "react-bootstrap";
import { Form } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getWithExpiry } from '../localstorageutils/localstore.js';
import TradeUtils from './utils/TradeUtils.js';

class ViewInvestment extends Component {

  constructor(props) {
    super(props);
    this.state = {
     "investment": this.props.location.state,
     "interesedunits": 0,
     "show_interest_modal": false,
     "talk_to_agent_modal": false,
    };
    this.authinfo=getWithExpiry("userinfo");
    console.log(this.state.investment);
  };

  componentDidMount() {
  };

  onDataChange = (key, value) => {
    console.log( "onDataChange" + key + ":" + value);
  };

  saveinvestment = () => {
        if(this.authinfo === null)
        {
            // route to login.
            return
        }
        console.log("Trying GetViewInvestment Data");
        const USER_API = '/rest/getinvestment';
        fetch(USER_API, {
                  method: "GET",
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                this.setState({"investments":data.investments});
         });
  };

  show_asset = (obj, variant) => {
    console.log(obj);
  };

  show_interest = () => {
    console.log("show_interest");
    this.setState({"show_interest_modal":true});
  };
 
  hide_interest = () => {
    console.log("hide_interest");
    this.setState({"show_interest_modal":false});
  };

  schedule_call = (obj, variant) => {
    console.log("schedule_call in a project");
  };

  render() {
   return (
     <Container className="px-0" fluid={true} style={{"min-height":"100vh"}}>
      {this.authinfo !== null && (
      <>    
      <Row>
       <Col sm={6}>
         <Card bg="light" border="primary" text="dark">
            <Card.Img variant="top" src={this.state.investment.doc_loc + "/main.jpeg"}  style={{ opacity:0.8 }}/>
            <Card.Body>
              <Card.Title>
                    {this.state.investment.name} Investment
              </Card.Title>
            </Card.Body>
         </Card>
       </Col>
       <Col sm={3}>
        <Card bg="light" border="primary" text="dark">
            <Card.Body>
                <Card.Title>Total Valuation</Card.Title>
                <Card.Text> <h2> {TradeUtils.formatCurrency(this.state.investment.total_value)} </h2> </Card.Text>
            </Card.Body>
        </Card>
        <br/>
        <Card bg="light" border="primary" text="dark">
            <Card.Body>
                <Card.Title>Offering Type</Card.Title>
                <Card.Text> <h2> Equity </h2></Card.Text>
            </Card.Body>
        </Card>
        <br/>
        <Card bg="light" border="primary" text="dark">
            <Card.Body>
                 <Card.Title>Price Per Share</Card.Title>
                <Card.Text> <h2> {TradeUtils.formatCurrency(this.state.investment.unit_value)} </h2> </Card.Text>
            </Card.Body>
        </Card>
       </Col>
       <Col sm={3}>
        <Card bg="light" border="primary" text="dark">
            <Card.Body>
                <Card.Title>Total Raised</Card.Title>
                <Card.Text> <h2> {this.state.investment.booked_units * this.state.investment.unit_value} </h2> </Card.Text>
            </Card.Body>
        </Card>
        <br/>
        <Card bg="light" border="primary" text="dark">
            <Card.Body>
                <Card.Title>Total Investors</Card.Title>
                <Card.Text><h2> {this.state.investment.booked_units} </h2></Card.Text>
            </Card.Body>
        </Card>
        <br/>
        <Card bg="light" border="primary" text="dark">
            <Card.Body>
                 <Card.Title>Minimum Investment</Card.Title>
                <Card.Text><h2> {TradeUtils.formatCurrency(this.state.investment.unit_value)} </h2></Card.Text>
            </Card.Body>
        </Card>
       </Col>
      </Row>
      <br/>
         <Row>
             <Col>
              <div className="d-grid">
                 <Button variant="secondary" onClick={this.show_interest}>
                          Show Interest
                 </Button>
              </div>
             </Col>
             <Col>
              <div className="d-grid">
                 <Button variant="secondary" onClick={this.schedule_call}>
                          Talk With An Agent
                 </Button>
              </div>
             </Col>
         </Row>
    <br/>
    <Row>
      <Col md={12}>
        <Tabs
              defaultActiveKey="0"
              id="fill-tab-example"
              className="mb-3"
              fill
         >
            <Tab eventKey="0" title="Investment Details" style={{
              }}>
                    Investment Details
             </Tab>
             <Tab eventKey="1" title="Why Invest">
                    Why you should Invest?
             </Tab>
             <Tab eventKey="2" title="About">
                    About
             </Tab>
             <Tab eventKey="3" title="Terms">
                    Terms
             </Tab>
             <Tab eventKey="4" title="Prices & Updates">
                    Prices & Updates
             </Tab>
             <Tab eventKey="5" title="Rewards">
                    Rewards
             </Tab>
             <Tab eventKey="6" title="Discussion">
                    Discussion
             </Tab>
         </Tabs>
      </Col>
    </Row>

     <Modal
        show={this.state.show_interest_modal}
        onHide={this.hide_interest}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Showing Interest In Project {this.state.investment.name}</Modal.Title>
        </Modal.Header>
        { (this.authinfo.name !== undefined && 
           this.authinfo.name !== null) && 
         (<Modal.Body>
            I {this.authinfo.name} am interested in purchasing 1 unit.

            Unit Value: {this.state.investment.unit_value}

            <Form>
                <Form.Group className="mb-3" controlId="Name">
                   <Form.Label className="text-center">
                     Name
                   </Form.Label>
                   <Form.Control type="number" placeholder="No Of Units Interested " value={this.state.interestedunits} onChange={(e) => this.onDataChange("name", e.target.value)}/>
                      </Form.Group>
            </Form>
          </Modal.Body>
         )
        }

        { (this.authinfo.name === undefined || this.authinfo.name === null) &&
         (<Modal.Body>
            You are not signedup for showing interest in the Project. Please signup and signin before submitting your interest.
            <p className="mb-0  text-center">
                  <a href="/signup" className="fw-bold">
                    Sign Up
                  </a>
            </p>

          </Modal.Body>
        )
        }
        <Modal.Footer>
            <Button variant="secondary" onClick={this.hide_interest}>
              Cancel
            </Button>
          { (this.authinfo.name !== undefined && this.authinfo.name !== null) &&
            (<Button variant="primary">Submit Interest</Button>)
            }
        </Modal.Footer>
      </Modal>
   </>
   )}
    </Container>
    
   );
  };

};

export default withRouter(ViewInvestment);
