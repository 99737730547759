const invimgdata=[
     "crowdseeds/livspace/main.jpeg",
     "crowdseeds/cars24/main.jpeg",
     "crowdseeds/1mg/main.jpeg",
     "crowdseeds/agnikul/main.jpeg",
     "crowdseeds/aknamed/main.jpeg",
     "crowdseeds/amber/main.jpeg",
     "crowdseeds/apna/main.jpeg",
     "crowdseeds/arzooo.com/main.jpeg",
     "crowdseeds/ather/main.jpeg",
     "crowdseeds/skyroot/main.jpeg",
     "crowdseeds/avail/main.jpeg",
     "crowdseeds/avanse/main.jpeg",
     "crowdseeds/avataar.me/main.jpeg",
     "crowdseeds/avishkaar/main.jpeg",
     "crowdseeds/awign/main.jpeg",
     "crowdseeds/aye/main.jpeg",
     "crowdseeds/bankbazaar.com/main.jpeg",
     "crowdseeds/betterhalf/main.jpeg",
     "crowdseeds/bharat/main.jpeg",
     "crowdseeds/bharatpe/main.jpeg",
     "crowdseeds/bigbasket/main.jpeg",
     "crowdseeds/billdesk/main.jpeg",
     "crowdseeds/bimape/main.jpeg",
     "crowdseeds/bimaplan/main.jpeg",
     "crowdseeds/bira/main.jpeg",
     "crowdseeds/blackbuck/main.jpeg",
     "crowdseeds/blue/main.jpeg",
     "crowdseeds/blusmart/main.jpeg",
     "crowdseeds/boat/main.jpeg",
     "crowdseeds/bobble/main.jpeg",
     "crowdseeds/bombay/main.jpeg",
     "crowdseeds/bookmyshow/main.jpeg",
     "crowdseeds/box8/main.jpeg",
     "crowdseeds/byju’s/main.jpeg",
     "crowdseeds/capital/main.jpeg",
     "crowdseeds/cardekho/main.jpeg",
     "crowdseeds/cartrade/main.jpeg",
     "crowdseeds/cashfree/main.jpeg",
     "crowdseeds/cashify/main.jpeg",
     "crowdseeds/cashkaro/main.jpeg",
     "crowdseeds/cell/main.jpeg",
     "crowdseeds/chaayos/main.jpeg",
     "crowdseeds/chai/main.jpeg",
     "crowdseeds/chingari/main.jpeg",
     "crowdseeds/chqbook/main.jpeg",
     "crowdseeds/chumbak/main.jpeg",
     "crowdseeds/citymall/main.jpeg",
     "crowdseeds/classplus/main.jpeg",
     "crowdseeds/clovia/main.jpeg",
     "crowdseeds/coindcx/main.jpeg",
     "crowdseeds/convin/main.jpeg",
     "crowdseeds/convosight/main.jpeg",
     "crowdseeds/country/main.jpeg",
     "crowdseeds/coverfox/main.jpeg",
     "crowdseeds/cred/main.jpeg",
     "crowdseeds/cropin/main.jpeg",
     "crowdseeds/cuemath/main.jpeg",
     "crowdseeds/cure.fit/main.jpeg",
     "crowdseeds/damensch/main.jpeg",
     "crowdseeds/dehaat/main.jpeg",
     "crowdseeds/delhivery/main.jpeg",
     "crowdseeds/digit/main.jpeg",
     "crowdseeds/digital/main.jpeg",
     "crowdseeds/docsapp/main.jpeg",
     "crowdseeds/dodla/main.jpeg",
     "crowdseeds/doubtnut/main.jpeg",
     "crowdseeds/droom/main.jpeg",
     "crowdseeds/dukaan/main.jpeg",
     "crowdseeds/dunzo/main.jpeg",
     "crowdseeds/earlysalary/main.jpeg",
     "crowdseeds/ecom/main.jpeg",
     "crowdseeds/electric_bikes/main.jpeg",
     "crowdseeds/embibe/main.jpeg",
     "crowdseeds/epigamia/main.jpeg",
     "crowdseeds/exotel/main.jpeg",
     "crowdseeds/ezetap/main.jpeg",
     "crowdseeds/f5/main.jpeg",
     "crowdseeds/faballey/main.jpeg",
     "crowdseeds/fabhotels/main.jpeg",
     "crowdseeds/fampay/main.jpeg",
     "crowdseeds/farmart/main.jpeg",
     "crowdseeds/financepeer/main.jpeg",
     "crowdseeds/financial/main.jpeg",
     "crowdseeds/fisdom/main.jpeg",
     "crowdseeds/five/main.jpeg",
     "crowdseeds/flatheads/main.jpeg",
     "crowdseeds/flipkart/main.jpeg",
     "crowdseeds/flock/main.jpeg",
     "crowdseeds/flower_turbines/main.jpeg",
     "crowdseeds/freecharge/main.jpeg",
     "crowdseeds/freight/main.jpeg",
     "crowdseeds/freshmenu/main.jpeg",
     "crowdseeds/freshtohome/main.jpeg",
     "crowdseeds/furlenco/main.jpeg",
     "crowdseeds/fyle/main.jpeg",
     "crowdseeds/fyllo/main.jpeg",
     "crowdseeds/gaana/main.jpeg",
     "crowdseeds/get/main.jpeg",
     "crowdseeds/girnarsoft/main.jpeg",
     "crowdseeds/glowroad/main.jpeg",
     "crowdseeds/gomechanic/main.jpeg",
     "crowdseeds/goodera/main.jpeg",
     "crowdseeds/gradeup/main.jpeg",
     "crowdseeds/grofers/main.jpeg",
     "crowdseeds/groww/main.jpeg",
     "crowdseeds/happay/main.jpeg",
     "crowdseeds/haptik/main.jpeg",
     "crowdseeds/healthifyme/main.jpeg",
     "crowdseeds/healthkart/main.jpeg",
     "crowdseeds/healthplix/main.jpeg",
     "crowdseeds/hike/main.jpeg",
     "crowdseeds/holisol/main.jpeg",
     "crowdseeds/homelane/main.jpeg",
     "crowdseeds/hopscotch/main.jpeg",
     "crowdseeds/housing.com/main.jpeg",
     "crowdseeds/hungerbox/main.jpeg",
     "crowdseeds/idfy/main.jpeg",
     "crowdseeds/incred/main.jpeg",
     "crowdseeds/indifi/main.jpeg",
     "crowdseeds/indwealth/main.jpeg",
     "crowdseeds/infra.market/main.jpeg",
     "crowdseeds/inmobi/main.jpeg",
     "crowdseeds/instamojo/main.jpeg",
     "crowdseeds/instantpay/main.jpeg",
     "crowdseeds/interviewbit/main.jpeg",
     "crowdseeds/itilite/main.jpeg",
     "crowdseeds/ixigo/main.jpeg",
     "crowdseeds/jai/main.jpeg",
     "crowdseeds/jiosaavn/main.jpeg",
     "crowdseeds/jumbotail/main.jpeg",
     "crowdseeds/jupiter/main.jpeg",
     "crowdseeds/juspay/main.jpeg",
     "crowdseeds/kims/main.jpeg",
     "crowdseeds/kinara/main.jpeg",
     "crowdseeds/kissht/main.jpeg",
     "crowdseeds/kodo/main.jpeg",
     "crowdseeds/kreditbee/main.jpeg",
     "crowdseeds/lava/main.jpeg",
     "crowdseeds/lead/main.jpeg",
     "crowdseeds/leadsquared/main.jpeg",
     "crowdseeds/leap/main.jpeg",
     "crowdseeds/lendingkart/main.jpeg",
     "crowdseeds/lenskart/main.jpeg",
     "crowdseeds/letstransport/main.jpeg",
     "crowdseeds/licious/main.jpeg",
     "crowdseeds/lido/main.jpeg",
     "crowdseeds/limeroad/main.jpeg",
     "crowdseeds/liquiloans/main.jpeg",
     "crowdseeds/livspace/main.jpeg",
     "crowdseeds/loadshare/main.jpeg",
     "crowdseeds/loconav/main.jpeg",
     "crowdseeds/locus.sh/main.jpeg",
     "crowdseeds/madco3d/main.jpeg",
     "crowdseeds/magicbricks/main.jpeg",
     "crowdseeds/magicpin/main.jpeg",
     "crowdseeds/mamaearth/main.jpeg",
     "crowdseeds/masai/main.jpeg",
     "crowdseeds/mcaffeine/main.jpeg",
     "crowdseeds/medgenome/main.jpeg",
     "crowdseeds/medibuddy/main.jpeg",
     "crowdseeds/medlife/main.jpeg",
     "crowdseeds/medplus/main.jpeg",
     "crowdseeds/meesho/main.jpeg",
     "crowdseeds/mfine/main.jpeg",
     "crowdseeds/miko/main.jpeg",
     "crowdseeds/milkbasket/main.jpeg",
     "crowdseeds/mobikwik/main.jpeg",
     "crowdseeds/mswipe/main.jpeg",
     "crowdseeds/mu/main.jpeg",
     "crowdseeds/myelin/main.jpeg",
     "crowdseeds/mygate/main.jpeg",
     "crowdseeds/myglamm/main.jpeg",
     "crowdseeds/mymoneymantra/main.jpeg",
     "crowdseeds/nearbuy/main.jpeg",
     "crowdseeds/neogrowth/main.jpeg",
     "crowdseeds/netmeds/main.jpeg",
     "crowdseeds/niki/main.jpeg",
     "crowdseeds/niyo/main.jpeg",
     "crowdseeds/nobroker/main.jpeg",
     "crowdseeds/nodwin/main.jpeg",
     "crowdseeds/northern/main.jpeg",
     "crowdseeds/nykaa/main.jpeg",
     "crowdseeds/ofbusiness/main.jpeg",
     "crowdseeds/okcredit/main.jpeg",
     "crowdseeds/ola/main.jpeg",
     "crowdseeds/oncolyze/main.jpeg",
     "crowdseeds/one97/main.jpeg",
     "crowdseeds/open/main.jpeg",
     "crowdseeds/oye/main.jpeg",
     "crowdseeds/oyo/main.jpeg",
     "crowdseeds/oziva/main.jpeg",
     "crowdseeds/pagarbook/main.jpeg",
     "crowdseeds/pariksha/main.jpeg",
     "crowdseeds/parkstreethomes/main.jpeg",
     "crowdseeds/paytm/main.jpeg",
     "crowdseeds/pee/main.jpeg",
     "crowdseeds/pepper/main.jpeg",
     "crowdseeds/pepperfry/main.jpeg",
     "crowdseeds/perpule/main.jpeg",
     "crowdseeds/phablecare/main.jpeg",
     "crowdseeds/pharmeasy/main.jpeg",
     "crowdseeds/piggyride/main.jpeg",
     "crowdseeds/pine/main.jpeg",
     "crowdseeds/pitstop/main.jpeg",
     "crowdseeds/planetspark/main.jpeg",
     "crowdseeds/play/main.jpeg",
     "crowdseeds/plum/main.jpeg",
     "crowdseeds/polygon/main.jpeg",
     "crowdseeds/portea/main.jpeg",
     "crowdseeds/porter/main.jpeg",
     "crowdseeds/practo/main.jpeg",
     "crowdseeds/pratilipi/main.jpeg",
     "crowdseeds/pristyn/main.jpeg",
     "crowdseeds/progcap/main.jpeg",
     "crowdseeds/proptiger/main.jpeg",
     "crowdseeds/psyonic/main.jpeg",
     "crowdseeds/purplle/main.jpeg",
     "crowdseeds/quess/main.jpeg",
     "crowdseeds/quicksell/main.jpeg",
     "crowdseeds/quikr/main.jpeg",
     "crowdseeds/quizizz/main.jpeg",
     "crowdseeds/rapido/main.jpeg",
     "crowdseeds/rategain/main.jpeg",
     "crowdseeds/raw/main.jpeg",
     "crowdseeds/razorpay/main.jpeg",
     "crowdseeds/rebel/main.jpeg",
     "crowdseeds/reliance/main.jpeg",
     "crowdseeds/renew/main.jpeg",
     "crowdseeds/rentomojo/main.jpeg",
     "crowdseeds/revv/main.jpeg",
     "crowdseeds/rivigo/main.jpeg",
     "crowdseeds/roposo/main.jpeg",
     "crowdseeds/safexpay/main.jpeg",
     "crowdseeds/saveo/main.jpeg",
     "crowdseeds/sbi/main.jpeg",
     "crowdseeds/scripbox/main.jpeg",
     "crowdseeds/setu/main.jpeg",
     "crowdseeds/shadowfax/main.jpeg",
     "crowdseeds/sharechat/main.jpeg",
     "crowdseeds/shiprocket/main.jpeg",
     "crowdseeds/shipsy/main.jpeg",
     "crowdseeds/shop101/main.jpeg",
     "crowdseeds/shopclues/main.jpeg",
     "crowdseeds/shopx/main.jpeg",
     "crowdseeds/shuttl/main.jpeg",
     "crowdseeds/signzy/main.jpeg",
     "crowdseeds/simpl/main.jpeg",
     "crowdseeds/skillmatics/main.jpeg",
     "crowdseeds/sleepycat/main.jpeg",
     "crowdseeds/slice/main.jpeg",
     "crowdseeds/smallcase/main.jpeg",
     "crowdseeds/snapdeal/main.jpeg",
     "crowdseeds/spinny/main.jpeg",
     "crowdseeds/square/main.jpeg",
     "crowdseeds/stanza/main.jpeg",
     "crowdseeds/star/main.jpeg",
     "crowdseeds/stashfin/main.jpeg",
     "crowdseeds/sugar/main.jpeg",
     "crowdseeds/swiggy/main.jpeg",
     "crowdseeds/synapsica/main.jpeg",
     "crowdseeds/teachmint/main.jpeg",
     "crowdseeds/testbook.com/main.jpeg",
     "crowdseeds/the/main.jpeg",
     "crowdseeds/toppr/main.jpeg",
     "crowdseeds/treebo/main.jpeg",
     "crowdseeds/trell/main.jpeg",
     "crowdseeds/trigenics/main.jpeg",
     "crowdseeds/true/main.jpeg",
     "crowdseeds/trulymadly.com/main.jpeg",
     "crowdseeds/turtlemint/main.jpeg",
     "crowdseeds/tvf/main.jpeg",
     "crowdseeds/udaan/main.jpeg",
     "crowdseeds/unacademy/main.jpeg",
     "crowdseeds/unbxd/main.jpeg",
     "crowdseeds/unlu/main.jpeg",
     "crowdseeds/upgrad/main.jpeg",
     "crowdseeds/upstox/main.jpeg",
     "crowdseeds/urban/main.jpeg",
     "crowdseeds/urbanpiper/main.jpeg",
     "crowdseeds/veer/main.jpeg",
     "crowdseeds/vernacular.ai/main.jpeg",
     "crowdseeds/verse/main.jpeg",
     "crowdseeds/virohan/main.jpeg",
     "crowdseeds/vivriti/main.jpeg",
     "crowdseeds/vvolt/main.jpeg",
     "crowdseeds/vymo/main.jpeg",
     "crowdseeds/wakefit/main.jpeg",
     "crowdseeds/webengage/main.jpeg",
     "crowdseeds/wheels/main.jpeg",
     "crowdseeds/whitehat/main.jpeg",
     "crowdseeds/winzo/main.jpeg",
     "crowdseeds/workindia/main.jpeg",
     "crowdseeds/wow!/main.jpeg",
     "crowdseeds/wow/main.jpeg",
     "crowdseeds/wysa/main.jpeg",
     "crowdseeds/xenabrewery/main.jpeg",
     "crowdseeds/xpressbees/main.jpeg",
     "crowdseeds/xyxx/main.jpeg",
     "crowdseeds/yellow/main.jpeg",
     "crowdseeds/yourstory/main.jpeg",
     "crowdseeds/yulu/main.jpeg",
     "crowdseeds/zestmoney/main.jpeg",
     "crowdseeds/zivame.com/main.jpeg",
     "crowdseeds/zolve/main.jpeg",
     "crowdseeds/zomato/main.jpeg",
     "crowdseeds/zoomcar/main.jpeg"
];

export {invimgdata}
