
const NoPageJumboTron = () => (

        <div className="jumbotron">
            <div class="row">
                <div class="col-sm-12">
                    <h1> We are working on the content, please browse other links while we work on this </h1>
{/*
                  <p>
                     <Button bsStyle="primary">Learn more</Button>
                  </p>
*/}
            </div>
          </div>
        </div>
);

export default NoPageJumboTron;
