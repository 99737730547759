import Container from 'react-bootstrap/Container';
import CollapsibleHeader from '../components/CollapsibleHeader.js';
import ViewInvestment from '../components/viewInvestment.js';
import ViewREInvestment from '../components/viewREInvestment.js';

const viewInvestmentPage = (props) => (
  <Container id="main-container" data-bs-theme="dark" fluid="true">
    <CollapsibleHeader/>
    <br/>
    <br/>

    <br/>
    { props.type === "normal" && (<ViewInvestment/>)}
    { props.type === "RE" && (<ViewREInvestment/>)}
    <br/>

  </Container>
);

export default viewInvestmentPage;
