import React, { Component} from 'react';
import '../styles/JumboTron.css';
import { connect } from 'react-redux';
import { withRouter } from './withRouter';
import { Col, Row, Card } from "react-bootstrap";
import MiniCardComponent from "./MiniCardComponent";
import {howtoinvest} from "./utils/InfoUtils";

class HowToInvest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      "email":"",
      "password":"",
      "data":"failure",
    }
  };

  render() {
    return (
       <Card bg="dark" data-bs-theme="dark">
         <br/>
         <Row style={{'text-align': 'center'}}>
           <h2> How To Invest </h2>
         </Row>
         <br/>
         <Row style={{}}>
           {Object.keys(howtoinvest).map((item, index) => (
            <Col md={6}>
              <MiniCardComponent variantbg="dark" itemno={item} title={howtoinvest[item]["name"]} msg={howtoinvest[item]["msg"]} newlines={howtoinvest[item]["newlines"]} mask={false} txtcolor="floralwhite" nocolor="floralwhite"  bgimagevariant="repeating-linear-gradient(40deg, blue, orange,green 1px)"/>
            </Col>
          ))}
        </Row>
        </Card>
  );
};

};

const mapStateToProps = (state) => {
   return {
      authdetails: state
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      saveloginname: authname => dispatch({type:'SAVE_LOGIN_NAME', payload: authname}),
   };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HowToInvest));
