import Container from 'react-bootstrap/Container';
import CollapsibleHeader from '../components/CollapsibleHeader.js';
import ViewXenaBrewery from '../components/investment_details/viewXenaBrewery.js';

const viewXenaBreweryPage = () => (
  <Container id="main-container" data-bs-theme="dark" fluid="true">
    <CollapsibleHeader/>
    <br/>
    <br/>

    <br/>
    <ViewXenaBrewery/>
    <br/>

  </Container>
);

export default viewXenaBreweryPage;
