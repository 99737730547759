import React, { Component} from 'react';
import '../styles/JumboTron.css';
import { withRouter } from './withRouter';
import { Button, Container, Card  } from "react-bootstrap";
import { Table } from 'react-bootstrap';
import TradeUtils from './utils/TradeUtils';
import { getWithExpiry } from '../localstorageutils/localstore.js';

class AllTradesComponent extends Component {

  constructor(props) {
    super(props);
    this.authinfo=getWithExpiry("userinfo");
    this.state = {
      "investments":[],
      "opentrades":{},
      "investment":{"username":"", "unit_value":0},
      "interestedunits":0,
      "status":0,
      "sellprice":0,
      "displaymsg": "Loading Trades",
      "show_buy_modal": false,
    }
  };

  componentDidMount() {
    this.getallopentrades();
  };

  showbuymodal = (ev, inv, modal_name) => {
    if(modal_name === "buy")
    {
        console.log("buy Modal");
        /* modify trade investment variable to be used by modal */
        this.setState({"investment":inv});
        this.setState({"show_buy_modal":true});
     }
  };

  hide_modal = (ev, modal_name) => {
    if(modal_name === "buy")
    {
        console.log("hide_trade_modal");
        this.setState({"show_buy_modal":false});
     }
  };

  getallopentrades = () => {
        if(this.authinfo === null)
        {
            // route to login.
            return;
        }
        console.log("Trying GetiAll Open Trades Data");
        const USER_API = '/rest/getalltrades';
        this.setState({"status":1});
        var indata = JSON.stringify({});
        fetch(USER_API, {
                  method: "POST",
                  body: indata,
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                if(data.investments.length === 0) {
                    this.setState({"displaymsg":"No Open User OpenTrades Available, Check back later"});
                    this.setState({"status":0});
                } else {
                    this.setState({"status":0});
                    this.setState({"investments":data.investments});
                    this.setState({"opentrades":data.open_trades});
                }
         });
   };

   perform_buy = () => {
        if(this.authinfo === null)
        {
            // route to login.
            return;
        }

        console.log("Trying Perform Buy");
        const INV_API = '/rest/performbuy';

        var indata = JSON.stringify({'opentrade':this.state.investment});
        fetch(INV_API, {
                  method: "POST",
                  body: indata,
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                this.setState({"show_buy_modal":false});
         });
    
   };

  arrayChunk = (arr, n) => {
    const array = arr.slice();
    const chunks = [];
    while (array.length) chunks.push(array.splice(0, n));
    return chunks;
  };

  getKeysArray = () => {
    return Object.keys(this.state.opentrades);
  }

  onDataChange = (key, value) => {
    if(key === "interestedunits")
    {
        if( Number(value) > this.state.investment.units)
        {
           this.setState({"modal_display_msg":"You only have " + this.state.investment.units + " to trade,"});
        }
        else
        {
           this.setState({"modal_display_msg":""});
           this.setState({"interestedunits":Number(value)});
        }
    }
  };

  render() {
   return (
    <div>
     { TradeUtils.getBuyModal(this) }
     { (this.state.opentrades !== undefined && this.state.opentrades.length !== 0) && 
       (<Container className="vh-100 px-0" fluid={true}> 
        { this.getKeysArray().map((invname, index) => { 
        return (
            <Card bg="light" border="primary" text="dark" style={{margin: '2px'}}>
            <Card.Header> All Open Trades </Card.Header>
            <Card.Body>
             <Table striped bordered hover size="sm">
             <thead>
                <tr>
                    <td> Investment Name </td>
                    <td> Units </td>
                    <td> Unit Value </td>
                    <td> Action </td>
                </tr>
             </thead>
             {this.state.opentrades[invname].filter(inv => inv.state === "opentrade").map(( listvalue, index ) => {
                return (
                    <tr key={index}>
                     <td>{listvalue.invname}</td>
                     <td>{listvalue.units}</td>
                     <td>{TradeUtils.formatCurrency(listvalue.unit_value)}</td>
                     <td><Button onClick={ (ev) => this.showbuymodal(ev, listvalue, "buy") }> Buy </Button></td>
                    </tr>
                );
            })}
            </Table>
          </Card.Body>
        </Card>);
       })}
       </Container>)})
     }
     </div>
   );
  };

};

export default withRouter(AllTradesComponent);
