import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import CollapsibleHeader from '../components/CollapsibleHeader.js';
import Login from '../components/Login.js';

class LoginPage extends Component {

   logincallback = () => {
   };

   render() {
    return(
      <Container id="main-container" data-bs-theme="dark" fluid="true">
        <CollapsibleHeader/>
        <Login/>
      </Container>
    )
   }
}

export default LoginPage;
