import Container from 'react-bootstrap/Container';
import CollapsibleHeader from '../components/CollapsibleHeader.js';
import NoPageJumboTron from '../components/NoPageJumboTron.js';

const NoPage = () => (
  <Container id="main-container" data-bs-theme="dark" fluid="true">
    <CollapsibleHeader/>
    <br/>
    <br/>
    <hr class="hr"/>
    <br/>
    <br/>
    <NoPageJumboTron/>
    <br/>
    <br/>
    <hr class="hr"/>
    <br/>
    <br/>
    
  </Container>
);

export default NoPage;
