import React, { Component} from 'react';
import '../../styles/JumboTron.css';
import '../../styles/Modal.css';
import '../../styles/viewInvestment.css';
import { withRouter } from '../withRouter';
import { Col, Row, Container, Card  } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import GeneralUtils from './GeneralUtils.js';
import { getWithExpiry } from '../../localstorageutils/localstore.js';

class ViewPsyonic extends Component {

  constructor(props) {
    super(props);
    this.authinfo=getWithExpiry("userinfo");
    this.state = {
     "investment": this.props.location.state,
     "interesedunits": 0,
     "show_interest_modal": false,
     "talk_to_agent_modal": false,
    }
    console.log(this.state.investment);
  };

  componentDidMount() {
  };

  onDataChange = (key, value) => {
    if(key === "interestedunits")
    {
        this.setState({"interestedunits":Number(value)});
    }
  };

  saveinvestment = () => {
        if(this.authinfo === null)
        {
            // route to login.
            this.props.navigate('/login');
            return
        }
        console.log("Trying Getflowerturbines Data");
        const USER_API = '/rest/getinvestment';
        fetch(USER_API, {
                  method: "GET",
                  headers: {
                        "Authorization": "Bearer " + ((this.authinfo === null) ? "invaliduser invalidtoken": this.authinfo.email + " " + this.authinfo.custattbs.token)
                  }
         }).then(response => {
                    return response.json();
         }).then(data => {
                console.log("Success");
                this.setState({"investments":data.investments});
         });
  };

  saveuserinvestment = () => {
        GeneralUtils.saveuserinvestment(this);
  };

  show_asset = (obj, variant) => {
    console.log(obj);
  };

  show_interest = () => {
    console.log("show_interest");
    this.setState({"show_interest_modal":true});
  };
 
  hide_interest = () => {
    console.log("hide_interest");
    this.setState({"show_interest_modal":false});
  };

  submit_interest = () => {
    console.log("submit_interest send submission to server -> " + this.state.interestedunits);
    this.setState({"show_interest_modal":false});
  };

  schedule_call = (obj, variant) => {
    console.log("schedule_call in a project");
  };

  render() {
   return (
     <Container className="vh-100 px-0" fluid={true}>
     {GeneralUtils.getMainPanelForInvestment(this)}
      <br/>
    <br/>
    <Row>
      <Col md={12} style={{ background:'#F0F0F0',fontSize:'15px' }}>
        <Tabs
              defaultActiveKey="0"
              id="fill-tab-example"
              className="mb-3"
              fill
         >
            <Tab eventKey="0" title="Investment Details" style={{
              }}>
                <h1> Beauty Combined With Aerodynamic Innovations That Change The Market For Small Wind  </h1>
              <Card bg="light" text="dark">
                <Card.Body>
                  <Card.Text>
                  <video width="100%" height="100%" controls>
                     <source src="../images/FlowerTurbines.mp4" type="video/mp4"/>
                     Your browser does not support the video tag.
                  </video>
                    </Card.Text>
                 </Card.Body>
               </Card>
              <Card bg="light" text="dark">
                <Card.Body>
                  <Card.Text>        
                    <p>
                        Technology being developed by Flower Turbines enables a new model in the small wind industry. Focused on creating beautiful, affordable, and efficient turbines for the urban/suburban market and tight spaces, we are paving the way for the future of distributed energy and providing investors with an equitable opportunity in the process. Our Bouquet (Cluster) Effect (whereby our Tulip turbines perform better when tightly clustered as opposed to the most common turbines which perform worse when clustered) gives us a key advantage to scaling wind farms and harnessing the electricity they produce.
                    </p>
                    <br/>
                    </Card.Text>
                 </Card.Body>
               </Card>
              <br/>
              <Card bg="light" text="dark">
                <Card.Body>
                  <Card.Text>
                    <p>
                        In fact, our testing found that each turbine added to a group increases the performance of each turbine in the group by 25%. That means that 4 correctly placed turbines is the equivalent of 8 separate ones. That is our key to competing with already mass-produced solar costs. We are now patenting electronic controls that we believe will enhance this Bouquet Effect even more. The following diagram shows how each of our smallest turbines produces more power as more turbines are added.
                     </p>
                    </Card.Text>        
                 </Card.Body>
               </Card>
             </Tab>
             <Tab eventKey="1" title="Why Invest">
               <h1> Reasons To Invest </h1>
                <Card bg="light" text="dark">
                 <Card.Body>
                   <Card.Text>        
                    <blockquote className="blockquote mb-0">
                      <p>
                        {' '}
                        Named one of India's top 10 most fundable companies by Business University Business School.{' '}
                      </p>
                     </blockquote>
                   </Card.Text>        
                  </Card.Body>
                </Card>
                <br/>
                <Card bg="light" text="dark" >
                 <Card.Body>
                  <Card.Text>        
                   <blockquote className="blockquote mb-0">
                     <p>
                        {' '}
                        Flower Turbines has successfully completed two oversubscribed StartupXChange campaigns through Reg CF and raised $9M in its last Reg A. {' '}
                     </p>
                    </blockquote>
                  </Card.Text>        
                 </Card.Body>
                </Card>
                <br/>
                <Card bg="light" text="dark">
                <Card.Body>
                  <Card.Text>        
                   <blockquote className="blockquote mb-0">
                     <p>
                        {' '}
Since our last raise, we have launched US manufacturing and sales in Lubbock, Texas, and are scaling up within the EU and negotiating additional international markets. Our already strong IP portfolio with multiple patents granted and pending in multiple countries mostly in aerodynamics is now enhanced by 10 game-changing patents in small wind turbine electronics, which could have the value of another company within the company.  
                     </p>
                    </blockquote>
                  </Card.Text>        
                </Card.Body>
                </Card>
                <br/>
                <Card bg="light" text="dark">
                <Card.Body>
                  <Card.Text>        
                   <blockquote className="blockquote mb-0">
                     <p>
                        {' '}
Right company in the right industry at the right time. New incentives for clean energy in the US, EU, and China put Flower Turbines on a rising tide.
                     </p>
                    </blockquote>
                  </Card.Text>        
                </Card.Body>
                </Card>
             </Tab>
             <Tab eventKey="2" title="About">
                    About
             </Tab>
             <Tab eventKey="3" title="Terms">
                    Terms
             </Tab>
             <Tab eventKey="4" title="Prices & Updates">
                    Prices & Updates
             </Tab>
             <Tab eventKey="5" title="Rewards">
                    Rewards
             </Tab>
             <Tab eventKey="6" title="Discussion">
                    Discussion
             </Tab>
         </Tabs>
      </Col>
    </Row>
     {GeneralUtils.getShowInterestModal(this)}
    </Container>
   );
  };

};

export default withRouter(ViewPsyonic);
